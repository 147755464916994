<template>

   
<b-modal
      ref="modalTransferir"
      centered
      hide-footer
      title="Transferir a billetera EQCoop"
      size="sm"
      @hidden="closeModalTransferir"
    >
      <div class="d-block text-center">

        <b-form
          autocomplete="off"
          @submit.prevent="onSubmitTransferirCripto"
        >

          <b-row>

              <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

                  <b-alert
                variant="secondary"
                show
                style="margin-bottom: 10px !important;"
              >
                <div class="alert-body">
                  
                         <p style="text-align: center;font-weight: bold;">Saldo de billetera</p>

                         <div  v-if="loading2">
                          <b-skeleton  height="65px" width="100%"  style="margin:0px"></b-skeleton>


                          </div>

                    
                        <div  v-else>


                            <p class="saldoDisponible" style="margin-bottom: -8px;">{{saldoDisponible }}<small style="font-weight: bold; font-size:10px"> USD</small></p>

                        <p style="text-align: center; font-size: 15px;">= {{balanceCripto}}<small style="font-weight: bold; font-size:10px"> {{currencyLabel}}</small></p>
                  
                          </div>
                
                </div>
              </b-alert>

              </b-col>

                 <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
            
            <p
                class="textLabel"
                style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
              >
                Monto a transferir hacia billetera EQCoop (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Es el monto en dólares (USD) que enviará de su billetera de criptomoneda hacia su saldo disponible en EQCoop.'"
                                  title="Monto de transferencia"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>
            </b-col>





              <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom:5px"
            >

              <AutoNumericVue
                v-model="mountTransfer"
                required
                type="text" inputmode="decimal"
                :disabled="isDisableTransferCripto2"
                :autofocus="false"
                @input="typeMountTransfer2"
                @blur="typeMountTransfer"
                class="autonumeric_input"
                :options="json_opciones_transferir"
                :placeholder="'$0.00'"
            ></AutoNumericVue>

            
             
            <p style="font-size: 14px; margin-bottom:0;font-style: italic;">= {{mountTransferenciaSaldo}} {{currencyLabel}}</p>

              
            
            </b-col>

            <b-col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                    >

                <b-alert
                    variant="primary"
                    show
                    style="margin-top:0px"
                  >
                    <div class="alert-body">
                      <p style=" text-align: center;font-size:12px">Monto máximo de conversión a dólares diario de su cuenta</p>
                      <b-skeleton v-if="loading2" style="margin-bottom:0px" width="100%" height="20px"  ></b-skeleton>
                      <p v-else style="text-align: center;font-size:25px;font-weight: bold; margin-bottom: 0px;">${{maxTransferencia}} <span style="font-size:12px">USD</span></p>
                      
                  
                    
                    </div>
                  </b-alert>

            </b-col>



            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

           

              <p
                class="textLabel"
                style="text-align: center;margin-bottom: 10px;"
              >
                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

              <PincodeInput
                v-model="pin"
                placeholder="-"
                :length="6"
                :autofocus="false"
                :secure="true"
                required
              />

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

              <b-button
                id="btn_transferir"
                ref="btn_transferir"
                type="submit"
                variant="primary"
                block
                :disabled="isDisableTransferCripto"
              >

                Convetir a dólares (USD)

              </b-button>

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"

              style="margin-top:10px"
            >

             <b-alert
              variant="primary"
              show
            >
              <div class="alert-body">
                <p style="margin:0; text-align: left;font-size:12px"> * Se cobrará una comisión del {{comision}} %.</p>
              
              </div>
            </b-alert>

            </b-col>

       


          </b-row>
        </b-form>

      </div>

    </b-modal>

     </template>
        
        <script>
        import {
          BAlert, VBTooltip,BSkeleton,BButton,VBPopover, BRow, BCol, BCard, BFormInput, BForm
        } from 'bootstrap-vue'

        import PincodeInput from 'vue-pincode-input'
import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
        export default {
          components: {
            BButton,
            PincodeInput,
            AutoNumericVue,
            BSkeleton,
            BFormInput,
            BRow,
            BCol,
            BForm,
            BAlert,
            BCard
          },
          props: ['idWallet','userId' ,'currency', 'tokenAuth'],
            directives: {
            'b-popover': VBPopover,
            'b-tooltip': VBTooltip,
          },
          data() {
    
            let currencyLabel="";
            if(this.currency === "BSC"){
            currencyLabel= "BNB"
            }else{
            currencyLabel=this.currency;
            }
        
            return {

                loading2:true,
                isDisableTransferCripto:false,
               isDisableTransferCripto2:false,
               balanceCripto:0,
               currencyLabel:currencyLabel,
               priceCripto:0,
               comision:0,
               saldoDisponible:0,
               maxTransferencia:0,
               pin:"",
                mountTransferenciaSaldo: "0.0000",
               totalMinEnvio:0,
               json_opciones_transferir:{},
               checkStatus:0,
               mountTransfer:"",
            }
          },
          computed: {
        
          },
          watch: {
           
          },
          mounted() {
            this.loading2=true;
            this.isDisableTransferCripto=true;
            this.isDisableTransferCripto2=true;
    
            this.$refs.modalTransferir.show();

            this.getBalanceTransferencia();
    
        
          },
          methods: {

            getBalanceTransferencia(){

                    const userId_json = { currency: this.currency, userId: this.userId, id: this.idWallet }
                    const user_string = JSON.stringify(userId_json)

                    const dataEncripted = this.$encryptCyptos.encrypt(user_string)

                    this.$httpsCryptos.post('/main/checkTransferencia/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
                        

                    
                    if (response.data.code == 200) {
                        this.loading2=false;
                        this.isDisableTransferCripto=false;
                        this.isDisableTransferCripto2=false;
                        this.balanceCripto= response.data.balanceCripto;
                        this.priceCripto= response.data.priceCripto;
                        this.comision= response.data.comisionRetiroCripto;
                        

                        this.maxTransferencia= response.data.maxTransferencia;
                        
                        
                        this.pin="";
                    
                        this.mountTransferenciaSaldo="0.0000";
                        this.mountTransfer="";



                            this.totalMinEnvio=5;


            
                            this.json_opciones_transferir={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:this.maxTransferencia, modifyValueOnWheel: false };



                            this.saldoDisponible= Number(Number(this.balanceCripto) * Number(this.priceCripto)).toFixed(2);
                        
                            this.$eventBus.$emit('reiniciarLimiteDiarioConversion', this.currency)
                    
                        this.checkStatusWalletsTransferir();
                    



                    } else {

                  


                        if (response.data.code == 401) {

                            this.$refs.modalTransferir.hide();

               

                            this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })

                        
                        localStorage.removeItem('userData')

                        

                        
                        

                        

                        
                        

                        // Redirect to login page
                        this.$router.push({ name: 'auth-login' })
                        }else{
                            this.getBalanceTransferencia();
                        }
                    }
                    }).catch(error => {

                      this.getBalanceTransferencia();


                    })

            },
    
            closeModalTransferir(){
              clearInterval(this.checkStatus);
              this.$eventBus.$emit('reiniciarModalTransferir')
                    
            },
            checkStatusWalletsTransferir(){

 
this.checkStatus = setInterval(() => {




   this.$httpsCryptos.post('/main/priceCripto/', { tokenAuth: this.tokenAuth, currency: this.currency}).then(response => {
       

     if (response.data.code == 200) {

       this.priceCripto= response.data.balance;

     } else {


       if (response.data.code == 401) {
         
         localStorage.removeItem('userData')



         
         this.$toast.error(response.data.message, {
           position: 'top-right',
           timeout: 3010,
           closeOnClick: true,
           pauseOnFocusLoss: false,
           pauseOnHover: true,
           draggable: true,
           draggablePercent: 0.4,
           showCloseButtonOnHover: false,
           hideProgressBar: true,
           closeButton: 'button',
           icon: true,
           rtl: false,
         })

         

         
         

         // Redirect to login page
         this.$router.push({ name: 'auth-login' })
       }
     }
   }).catch(error => {
     
   })


   
   }, 30000)

},
            
    onSubmitTransferirCripto(event){

event.preventDefault();

 const userId_json = { userId: this.userId, pin: this.pin, mount: this.mountTransfer, id: this.idWallet }
 const user_string = JSON.stringify(userId_json);
   

  
    this.isDisableTransferCripto=true;
    this.isDisableTransferCripto2=true;


     const dataEncripted = this.$encryptCyptos.encrypt(user_string)

   document.getElementById('btn_transferir').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Transferiendo';

     this.$httpsCryptos.post('/main/retirarCripto/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
             this.isDisableTransferCripto=false;
             this.isDisableTransferCripto2=false;

           document.getElementById('btn_transferir').innerHTML = "Convetir a saldo";

           
   
       
           if (response.data.code == 200) {

     
             this.pin= "";
             this.mountTransfer="";


   
               

             this.$refs.modalTransferir.hide();

             this.$toast.success(response.data.message, {
           position: 'top-right',
           timeout: 3010,
           closeOnClick: true,
           pauseOnFocusLoss: false,
           pauseOnHover: true,
           draggable: true,
           draggablePercent: 0.4,
           showCloseButtonOnHover: false,
           hideProgressBar: true,
           closeButton: 'button',
           icon: true,
           rtl: false,
         });

         this.$eventBus.$emit('reiniciarLimiteDiarioConversion', this.currency)

         this.$eventBus.$emit('refreshSaldoCripto')

           } else {

               this.$toast.error(response.data.message, {
               position: 'top-right',
               timeout: 3010,
               closeOnClick: true,
               pauseOnFocusLoss: false,
               pauseOnHover: true,
               draggable: true,
               draggablePercent: 0.4,
               showCloseButtonOnHover: false,
               hideProgressBar: true,
               closeButton: 'button',
               icon: true,
               rtl: false,
             })


             if (response.data.code == 401) {
               
               localStorage.removeItem('userData')



               
               

             

               
               

               // Redirect to login page
               this.$router.push({ name: 'auth-login' })
             }
           }
         }).catch(error => {

           this.$toast.error("Ocurrió un error inesperado", {
             position: 'top-right',
             timeout: 3010,
             closeOnClick: true,
             pauseOnFocusLoss: false,
             pauseOnHover: true,
             draggable: true,
             draggablePercent: 0.4,
             showCloseButtonOnHover: false,
             hideProgressBar: true,
             closeButton: 'button',
             icon: true,
             rtl: false,
           })
         })

},

    typeMountTransfer(){

var t = this.mountTransfer;



     if(t === "" || t === "null" || t === null || t===0  ){

 

       this.mountTransferenciaSaldo="0.0000";
       this.mountTransfer="";
       




     }else{

     

       

         if(Number(t) < Number(this.totalMinEnvio)){

       


        this.mountTransfer ="";
           this.mountTransferenciaSaldo="0.0000";


          this.$toast.error("El monto a transferir debe ser mayor a $"+this.totalMinEnvio+" USD", {
           position: 'top-right',
           timeout: 3010,
           closeOnClick: true,
           pauseOnFocusLoss: false,
           pauseOnHover: true,
           draggable: true,
           draggablePercent: 0.4,
           showCloseButtonOnHover: false,
           hideProgressBar: true,
           closeButton: 'button',
           icon: true,
           rtl: false,
         })

   

       }else{




       

         // this.mountSend = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;


         if(Number(t) > Number(this.saldoDisponible)){

 



         
                   this.mountTransfer ="";
   
                   
                   this.mountTransferenciaSaldo="0.0000";

        this.$toast.error("El monto $"+t+" USD supera su saldo de billetera.", {
           position: 'top-right',
           timeout: 3010,
           closeOnClick: true,
           pauseOnFocusLoss: false,
           pauseOnHover: true,
           draggable: true,
           draggablePercent: 0.4,
           showCloseButtonOnHover: false,
           hideProgressBar: true,
           closeButton: 'button',
           icon: true,
           rtl: false,
         })

         }else{

         
   

         
             let mounTrans=Number(t)  / Number(this.priceCripto);

               if(Number(mounTrans.toString().length) > 9){
                   this.mountTransferenciaSaldo= Number(mounTrans.toString().substring(0, 9));
               }else{

                 this.mountTransferenciaSaldo= mounTrans;

               }

             

         }

       
       }

     }






},

            typeMountTransfer2(value){
    

    if(value === "" || value === "null" || value === null  || value === 0){

       
         

         this.mountTransferenciaSaldo="0.0000";

       
          this.isDisableTransferCripto=false;




       }else{


          if(Number(value) < Number(this.totalMinEnvio)){

      
           this.isDisableTransferCripto=true;

           }else{

              if(Number(value) > Number(this.saldoDisponible)){

               
                this.isDisableTransferCripto=true;

             }else{

                this.isDisableTransferCripto=false;
                   
                   let mounTrans=Number(value)  / Number(this.priceCripto);

                   if(Number(mounTrans.toString().length) > 9){
                       this.mountTransferenciaSaldo= Number(mounTrans.toString().substring(0, 9));
                   }else{

                     this.mountTransferenciaSaldo= mounTrans;

                   }

             }

              



           }

  

    



       

    


       }

},
        
            
        
        
          },
        }
        </script>
        
        <style lang="scss">
        
    
        
        
        </style>
        