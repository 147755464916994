<template>

<b-modal
      ref="modalBuyCripto"
      centered
      hide-footer
      :title="title"
      size="sm"
      @hidden="closeModalBuy"
    >
      <div class="d-block text-center">

        <b-form
          autocomplete="off"
          @submit.prevent="onSubmitBuyCripto"
        >

          <b-row>

              <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

              <b-alert
                variant="secondary"
                show
                style="margin-bottom: 10px !important;"
              >
                <div class="alert-body">

          

                  <b-skeleton v-if="loading2"  height="35px" width="100%" style="margin:0px"></b-skeleton>
                  
                <p v-else class="saldoDisponible"><span style="font-size:25px">$</span>{{saldoDisponible}}<small class="currency_cripto">USD</small></p>
                <p style="font-size: 12px;">Saldo de su billetera</p>

                
                </div>
              </b-alert>


              </b-col>

            



               <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

             <p style="font-size: 14px;text-align: center;margin-bottom: 0;">Ingrese el monto a comprar</p>

            </b-col>

           



             <b-col
             cols="12"
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >
            
                <AutoNumericVue
                v-model="mountBuy"
                required
                type="text" inputmode="decimal"
                :disabled="isDisabledBuyCripto"
                :autofocus="false"
                @input="onChangeMount"
                @blur="onBlurMount"
                class="autonumeric_input"
                :options="json_opciones_buy"
                :placeholder="'$0.00'"
            ></AutoNumericVue>

              
            <p style="font-size: 14px; margin-bottom:0;font-style: italic;">= {{mountCripto}} {{currencyLabel}}</p>


         

            </b-col>

            <b-col
                      sm="12"
                      md="12"
                      lg="12"
                      xl="12"
                    >

                <b-alert
                    variant="primary"
                    show
                    style="margin-top:0px"
                  >
                    <div class="alert-body">
                      <p style=" text-align: center;font-size:12px">Monto máximo de compra mensual de su cuenta</p>
                      <b-skeleton v-if="loading2" style="margin-bottom:0px" width="100%" height="20px"  ></b-skeleton>
                      <p v-else style="text-align: center;font-size:25px;font-weight: bold; margin-bottom: 0px;">${{maxCompra}} <span style="font-size:12px">USD</span></p>
                      
                  
                    
                    </div>
                  </b-alert>

            </b-col>
         

  

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

        

              <p
                class="textLabel"
                style="text-align: center;margin-top: 10px;margin-bottom: 10px;"
              >
                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

              <PincodeInput
                v-model="pin"
                placeholder="-"
                :length="6"
                :autofocus="false"
                :secure="true"
                :disabled="isDisabledBuyCripto"
                required
              />

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

              <b-button
                id="btn_buy"
                ref="btn_buy"
                type="submit"
                variant="primary"
                block
                :disabled="isDisabledButtonBuy"
              >

                Comprar saldo {{currencyLabel}}

              </b-button>

            </b-col>

              <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"

              style="margin-top:10px"
            >

             <b-alert
              variant="primary"
              show
            >
              <div class="alert-body">
                <p style="margin:0; text-align: left;font-size:12px"> * Se cobrará una comisión del <b>{{comision}} %</b>.</p>
                <p style="margin:0; text-align: left;font-size:12px"> * El monto máximo por transacción es de <b>${{maxTransaccion}} USD</b>.</p>
              
              </div>
            </b-alert>

            </b-col>

       


          </b-row>
        </b-form>

      </div>

    </b-modal>
    
         </template>
            
            <script>
            import {
              BAlert, VBTooltip,BSkeleton,BButton,VBPopover, BRow, BCol, BCard, BFormInput, BForm
            } from 'bootstrap-vue'
    
            import PincodeInput from 'vue-pincode-input'
    import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
            export default {
              components: {
                BButton,
                PincodeInput,
                AutoNumericVue,
                BSkeleton,
                BFormInput,
                BRow,
                BCol,
                BForm,
                BAlert,
                BCard
              },
              props: ['idWallet','userId' ,'currency', 'tokenAuth'],
                directives: {
                'b-popover': VBPopover,
                'b-tooltip': VBTooltip,
              },
              data() {
        
                let currencyLabel="";
                if(this.currency === "BSC"){
                currencyLabel= "BNB"
                }else{
                currencyLabel=this.currency;
                }
            
                return {
                    checkStatus:0,
                    loading2:true,
                    isDisabledButtonBuy:false,
                    isDisabledBuyCripto:false,
                    title:"",
                    mountCripto:0,
                    mountBuy:"",
                    pin:"",
                    currencyLabel:currencyLabel,
                    saldoDisponible:0,
                    priceCripto:0,
                    maxCompra:0,
                    maxTransaccion:0,
                    comision:0,
                    json_opciones_buy:{},
                }
              },
              computed: {
            
              },
              watch: {
               
              },
              mounted() {
            
                this.loading2=true;
                    this.isDisabledButtonBuy=true;
                    this.isDisabledBuyCripto= true;
                    this.$refs.modalBuyCripto.show();

                this.getBalanceBuy();
        
            
              },
              methods: {

                

                 onChangeMount(cantidad){

                        

                    if(cantidad == null || cantidad == "null" || this.mountBuy ==""){


                    this.mountCripto=0;

                        this.isDisabledButtonBuy=false;

                    }else{

                    if(Number(cantidad) <= Number("0")){
                    this.mountBuy="";


                        this.isDisabledButtonBuy=false;

                    }else{

                        this.mountBuy=cantidad;

                        if(this.currency === "USDT"){
                        this.mountCripto= Number(Number(this.mountBuy) / Number(this.priceCripto)).toFixed(2)
                    }else{
                        this.mountCripto= Number(Number(this.mountBuy) / Number(this.priceCripto)).toFixed(6)
                    }

                    let montoWithComision= Number(this.mountBuy) + Number("0.50");



                    if(Number(montoWithComision) < Number(this.saldoDisponible)){

                    this.isDisabledButtonBuy=false;



                    }

                    }

                    }




                    },

                onBlurMount(){
                        

                        if(this.mountBuy == null || this.mountBuy == "null" || this.mountBuy =="" || this.mountBuy == 0){

                        this.mountCripto=0;
                        this.mountBuy="";

                        this.isDisabledButtonBuy=false;

                    }else{



                    if(this.currency === "USDT"){
                        this.mountCripto= Number(Number(this.mountBuy) / Number(this.priceCripto)).toFixed(2)
                    }else{
                        this.mountCripto= Number(Number(this.mountBuy) / Number(this.priceCripto)).toFixed(6)
                    }


                        

                            let montoWithComision= Number(this.mountBuy) + Number("0.50");



                        if(Number(montoWithComision) > Number(this.saldoDisponible)){
                        

                            
                            this.isDisabledButtonBuy=false;
                            this.mountBuy="";

                            this.$toast.error("El monto a comprar supera a su saldo de billetera.", {
                                    position: 'top-right',
                                    timeout: 3010,
                                    closeOnClick: true,
                                    pauseOnFocusLoss: false,
                                    pauseOnHover: true,
                                    draggable: true,
                                    draggablePercent: 0.4,
                                    showCloseButtonOnHover: false,
                                    hideProgressBar: true,
                                    closeButton: 'button',
                                    icon: true,
                                    rtl: false,
                                })



                    


                        }else{

                            this.isDisabledButtonBuy=false;


                        }

                    


                    }

                    },
    
                getBalanceBuy(){
    
                                        
                    
                        this.title="Comprar saldo en "+this.currencyLabel;

                   

                    this.mountCripto=0;
                    this.mountBuy="";


          


                    const userId_json = { currency: this.currency, userId: this.userId }
                    const user_string = JSON.stringify(userId_json)

                    const dataEncripted = this.$encryptCyptos.encrypt(user_string)

                    this.$httpsCryptos.post('/main/getBalancePriceCripto/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {



                    if (response.data.code == 200) {
                        this.loading2=false;
                        this.isDisabledButtonBuy=false;

                    this.saldoDisponible= Number(response.data.balance).toFixed(2);
                    this.priceCripto= response.data.priceCripto;
                    this.comision= response.data.comisionBuyCripto;
                    this.mountBuy = "";
                    this.pin="";

                    this.maxCompra= response.data.maxCompra;
                    this.maxTransaccion= response.data.maxTransaccion;


                    this.json_opciones_buy={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:this.maxTransaccion, modifyValueOnWheel: false };





                        this.isDisabledBuyCripto= false;



                        this.mountCripto= Number(this.mountBuy) / Number(this.priceCripto)
                        
                        this.$eventBus.$emit('reiniciarMontoCompra', this.currency)
                    this.$eventBus.$emit('reiniciarCompraByMes', this.currency)


                        this.checkStatusWalletsBuy();




                    } else {
                     



                    if (response.data.code == 401) {
                        this.$refs.modalBuyCripto.hide();
              
                        this.$toast.error(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                                })

                        
                        localStorage.removeItem('userData')


                        

                        // Redirect to login page
                        this.$router.push({ name: 'auth-login' })
                    }else{
                        this.getBalanceBuy();
                    }
                    }
                    }).catch(error => {




                        this.getBalanceBuy();

                        

                    })
    
                },

                onSubmitBuyCripto(event) {
                    event.preventDefault();

                        const userId_json = { userId: this.userId, pin: this.pin, mount: this.mountBuy, recipientAccountId: this.idWallet }
                        const user_string = JSON.stringify(userId_json);
                        

                        
                        this.isDisabledButtonBuy=true;
                        this.isDisabledBuyCripto= true;

                            const dataEncripted = this.$encryptCyptos.encrypt(user_string)

                        document.getElementById('btn_buy').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Realizando compra'

                

                    this.$httpsCryptos.post('/main/buyCrypto/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
                        this.isDisabledButtonBuy=false;
                        this.isDisabledBuyCripto= false;

                        document.getElementById('btn_buy').innerHTML = 'Comprar saldo '+this.currencyLabel;

                        
                
                    
                        if (response.data.code == 200) {

                
                        this.pin= "";
                        this.mountBuy="";


                
                            

                        this.$refs.modalBuyCripto.hide();

                        this.$toast.success(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    });

                 
                    
                    this.$eventBus.$emit('refreshSaldoCripto')
                    this.$eventBus.$emit('reiniciarMontoCompra', this.currency)
                    this.$eventBus.$emit('reiniciarCompraByMes', this.currency)

                        } else {

                            this.$toast.error(response.data.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                        })


                        if (response.data.code == 401) {
                            
                            localStorage.removeItem('userData')



                            
                            

                        

                            
                            

                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                        }else{

                            if( response.data.code === 505){
                            this.$refs.modalBuyCripto.hide();
                            this.$eventBus.$emit('reiniciarMontoCompra', this.currency);
                            this.$eventBus.$emit('reiniciarCompraByMes', this.currency)
                            }

                        }
                        }
                    }).catch(error => {

                    
                        this.$toast.error("Ocurrió un error inesperado", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        })
                    })

                    },

        
                closeModalBuy(){
                    clearInterval(this.checkStatus);
                  this.$eventBus.$emit('reiniciarModalBuy')
                        
                },
                checkStatusWalletsBuy(){
    
     
                        this.checkStatus = setInterval(() => {
                        
                        
                        
                        
                        this.$httpsCryptos.post('/main/priceCripto/', { tokenAuth: this.tokenAuth, currency: this.currency}).then(response => {
                            
                        
                            if (response.data.code == 200) {
                        
                            this.priceCripto= response.data.balance;
                        
                            } else {
                        
                        
                            if (response.data.code == 401) {
                                
                                localStorage.removeItem('userData')
                        
                        
                        
                                
                                this.$toast.error(response.data.message, {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                                })
                        
                                
                        
                                
                                
                        
                                // Redirect to login page
                                this.$router.push({ name: 'auth-login' })
                            }
                            }
                        }).catch(error => {
                            
                        })
                        
                        
                        
                        }, 30000)
                        
                        },
                                    
                            
            
            
              },
            }
            </script>
            
            <style lang="scss">
            
        
            
            
            </style>
            