<template>

<b-row v-if="loading" > 
 
  <b-col  cols="10" sm="10" md="10" xl="10" lg="10">

<h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
      <feather-icon
                icon="ListIcon"
                size="21"
                class="color_icon"
                /> Últimas transacciones
        </h4>

</b-col>


<b-col  cols="2" sm="2" md="2" xl="2" lg="2">
<b-skeleton width="100%"  height="20px"></b-skeleton>

</b-col>



<b-col  cols="4" sm="4" md="4" xl="4" lg="4">


</b-col>

<b-col  cols="4" sm="4" md="4" xl="4" lg="4">

<b-skeleton width="100%"  height="30px"></b-skeleton>

</b-col>

<b-col  cols="4" sm="4" md="4" xl="4" lg="4">


</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


    </b-col>

    <b-col  cols="6" sm="6" md="6" xl="6" lg="6">

    <b-skeleton width="100%"  height="30px"></b-skeleton>

    </b-col>

    <b-col  cols="3" sm="3" md="3" xl="3" lg="3">


    </b-col>

<b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="margin-bottom:10px">




                  <b-skeleton-table
              :rows="2"
              :columns="columnas"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>

</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>

<b-col  cols="6" sm="6" md="6" xl="6" lg="6">

<b-skeleton width="100%"  height="30px"></b-skeleton>

</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>

<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2">
<b-skeleton width="100%"  height="30px"></b-skeleton>


</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2" align="center">
<b-skeleton width="30px" type="avatar"   height="30px"></b-skeleton>


</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2">
<b-skeleton width="100%"  height="30px"></b-skeleton>


</b-col>


<b-col  cols="3" sm="3" md="3" xl="3" lg="3">


</b-col>
  

 </b-row>

 <b-row v-else-if="code200">
  <b-col  cols="10" sm="10" md="10" xl="10" lg="10">

<h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
      <feather-icon
                icon="ListIcon"
                size="21"
                class="color_icon"
                /> Últimas transacciones
        </h4>

</b-col>

<b-col  cols="2" sm="2" md="2" xl="2" lg="2" style="    text-align: right;">


<p style="cursor:pointer;    margin: 0;" @click="refresh()" > 
                                                            <feather-icon
                                                            icon="RefreshCcwIcon"
                                                            size="16"
                                                            style="margin-right:5px"
                                                            /> <span class="d-none d-sm-none d-md-inline-block d-lg-inline-block d-xl-inline-block"> Recargar </span> </p>



</b-col>



<b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="  overflow: auto; padding: 0px; margin-top:15px">

  <table class="table tablesorter"  id="datatable" >
                              <thead class="thead-light">
                                <tr>
                                  <th data-priority="1">Fecha/Hora</th>
                                   <th data-priority="2">ID transacción</th>
                                   <th data-priority="3">Dólares</th>
                                  <th >{{currency}}</th>
                                 
                                  <th>Estado</th>
                                 
                                </tr>
                              </thead>
                              <tbody>


                                <tr v-for="transaccion in transactions" :key="transaccion.idTransaction">

                                 <td> {{ transaccion.date }}</td>

                                 <td>
                                        
                              

                                         <b-input-group>

                                            <b-form-input
                                            v-model="transaccion.txId"
                                            
                                                readonly>
                                               
                                                </b-form-input>
                                          
                                            <b-input-group-append>
                                                <b-button
                                                @click="onCopy(transaccion.txId)"
                                                variant="outline-primary"
                                                >

                                                <feather-icon
                                            icon="CopyIcon"
                                            size="15"
                                            
                                            /> 
                                                </b-button>
                                            </b-input-group-append>
                                            </b-input-group>
                                        
                                        </td>
                               
                                        <td>${{ transaccion.amountDolar }}</td>
                               
                               
                                      <td>{{ transaccion.amountCripto }} {{currency}}</td>

                                    
                                   

                                     <td v-if="transaccion.transactionType === 'Crédito'">
                                     <b-badge variant="success">
                                        Crédito
                                      </b-badge>
                                  
                                  </td>
                                  <td v-else-if="transaccion.transactionType === 'Débito'">

                                     <b-badge variant="warning">
                                        Débito
                                      </b-badge>
                                   
                                    
                                    </td>

                                       <td v-else>

                                     <b-badge variant="danger">
                                        Fallido
                                      </b-badge>
                                   
                                    
                                    </td>
                          

                                </tr>
                              </tbody>
                            </table>


 </b-col>


 

 </b-row>

 <b-row v-else-if="empty">

  <b-col  cols="12" sm="12" md="12" xl="12" lg="12">

<h4 class="font-weight-bolder" style="text-align: left !important;    margin: 0;">
      <feather-icon
                icon="ListIcon"
                size="21"
                class="color_icon"
                /> Últimas transacciones
        </h4>

</b-col>

 <b-col  align="center" cols="12" sm="12" md="12" xl="12" lg="12">
          <img width="150px" alt="No transacciones" src="/img/wallets_crypto.svg">
                                       
                                                  

                         <p class="errorTexto">
                        No se encontraron transacciones
                      </p>

              </b-col>

 <b-col  cols="12" sm="12" md="12" xl="12" lg="12" style="    text-align: center;">

                           <b-button   variant="primary"  @click="refresh()" > 
                                         <feather-icon
                                          icon="RefreshCcwIcon"
                                          size="16"
                                         
                                          style="margin-right:5px; color:#fff"
                                          /> Recargar transacciones </b-button>


 </b-col>
</b-row>


<b-row v-else>

 <b-col  cols="12" sm="12" md="12" xl="12" lg="12" align="center">


             <feather-icon
             icon="FrownIcon"
             size="50"
             />
           <p class="errorTexto">
                Ocurrió un error al cargar los datos
              </p>

              <b-button
                id="btn_refresh"
                ref="btn_refresh"
                variant="primary"
                @click="refresh"
              >

                Intentar de nuevo

              </b-button>
                
        

              </b-col>
</b-row>

</template>

<script>
import {
  BButton, BRow, BCol,BBadge,BSkeleton, BCard,BSkeletonTable, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BFormGroup,
} from 'bootstrap-vue'
import "jquery/dist/jquery.min.js";
import $ from "jquery";
import 'datatables.net-responsive-dt/css/responsive.dataTables.min.css'
import 'datatables.net-responsive-dt/js/responsive.dataTables.min.js'

export default {
  components: {
    BButton,
    BSkeleton,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BInputGroupAppend,
    BFormInput,
    BSkeletonTable,
    BRow,
    BCol,
    BCard,
    BBadge
  },
  props: ['userId','tokenAuth', 'idWallet', 'currency'],
  data() {

    const mq = window.matchMedia( "(min-width: 990px)" );
        let columnas=6;

        if (!mq.matches) {

          columnas =3;
          }

    return {
      code200: false,
      loading: true,
      columnas:columnas,
     empty:false,
       transactions:[],

    }
  },
  computed: {

  },
  watch: {

  },
  mounted() {


    this.$httpsCryptos.post('/main/getTransactionsLedger/', { tokenAuth: this.tokenAuth, idWallet: this.idWallet }).then(response => {
          this.loading = false

       
      if (response.data.code == 200) {

  
       
        this.transactions= response.data["transacciones"];

     
                     
      

        this.code200 = true;

                         setTimeout(() => {
                                    $("#datatable").DataTable({
                                      responsive: true,
                                      "ordering": false,
                                      lengthMenu: [
                                        [3,5,10, 25, 50, -1],
                                        [3,5,10, 25, 50, "All"],
                                      ],
              
                                      pageLength: 3, 
                                      "language": {
                                              "search": "Buscar:",
                                              "sInfo":      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
	                                            "sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                                              "searchPlaceholder": "Ingrese algún dato",
                                              "zeroRecords": "Lo sentimos, no se encontraron resultados",
                                              "processing": "Buscando. Espere por favor...",
                                              "sLengthMenu":     "Mostrar _MENU_ registros",
                                              "oPaginate": {
                                                      "sFirst":    "Primero",
                                                      "sLast":     "Último",
                                                      "sNext":     "Siguiente",
                                                      "sPrevious": "Anterior"
                                                  },

                                              }
                                    });
                                  });

                

         

      } else {
        this.code200 = false;


        if (response.data.code == 401) {

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
          
          
          localStorage.removeItem('userData')



          
          

           

          
          

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {

             if (response.data.code == 404) {

                 this.empty = true

            }
         
        }
      }
    }).catch(error => {
         this.code200 = false
    })
  },
  methods: {

    refresh() {
      this.$eventBus.$emit('reiniciarTransaccionesCripto')
    }, onCopy(copy) {
      navigator.clipboard.writeText(copy);
      this.$toast.success('Texto copiado!', {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    },
    onError() {
      this.$toast.error('Falló en copiar el texto', {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    },


  },
}
</script>

<style lang="scss">


</style>
