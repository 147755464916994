<template>

    
<b-modal
      ref="modalSendCripto"
      centered
      hide-footer
      title="Transferencia de criptomonedas"
      size="sm"
      @hidden="closeModalSend"
    >
      <div class="d-block text-center">

        <b-row>
          <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

                   <b-alert
                variant="secondary"
                show
                style="margin-bottom: 10px !important;"
              >
                <div class="alert-body">
                  
                        <p style="text-align: center;font-weight: bold;">Saldo de billetera</p>

                        <div  v-if="loading2">
                          <b-skeleton  height="70px" width="100%" style="margin:0px"></b-skeleton>


                          </div>

                    
                        <div  v-else>

                          <p  class="saldoDisponible"><span style="font-size:25px">$</span>{{saldoDisponible }}<small style="font-weight: bold; font-size:10px"> USD</small></p>

                          <p style="text-align: center; font-size: 15px;">= {{balanceCripto}}<small style="font-weight: bold; font-size:10px"> {{currencyLabel}}</small></p>



                        </div>

            

                
                </div>
              </b-alert>

              </b-col>
        </b-row>


        <b-form
        v-if="showAddress"
          autocomplete="off"
          @submit.prevent="onSubmitSearchAddress"
        >

         <b-row >

              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
                style="margin-bottom:15px"
              >

                        <p
                  class="textLabel"
                  style="text-align: left;margin-top: 0px;margin-bottom: 5px;"
                >
                  Ingrese el address de billetera a transferir (*): <feather-icon
                                    icon="HelpCircleIcon"
                                    size="16"
                                      v-b-popover.hover.bottom.html="'Es el address de la billetera a la cual deseas transferir tus criptomonedas.'"
                                    title="Address de billetera a transferir"
                                    style="margin-right: 5px;cursor:pointer;"
                                    /> </p>

                <b-input-group>
                  <b-input-group-prepend is-text>
                    <feather-icon icon="UserIcon" />
                  </b-input-group-prepend>
                  <b-form-input required :readonly="isDisabledSendCripto2" v-model="addressEnviar" placeholder="Address de billetera" />
                </b-input-group>

              </b-col>


              <b-col
                sm="12"
                md="12"
                lg="12"
                xl="12"
              >

              <b-button
                  id="btn_continuar_send"
                  ref="btn_continuar_send"
                  variant="primary"
                  block
                  :disabled="isDisabledSendCripto"
                  type="submit"
                >

                  Siguiente 

                </b-button>



              </b-col>


              </b-row>

          </b-form>

        <b-form
        v-else
          autocomplete="off"
          @submit.prevent="onSubmitSendCripto"
        >

       

          


              <b-row>


              <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-bottom:5px"
            >

            
                      <p
                class="textLabel"
                style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
              >
                Monto a transferir + comisión (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'El monto en dólares (USD) a enviarse en valor de criptomoneda. Este monto debe ser igual o mayor al monto mínimo + comisión.'"
                                  title="Monto de transferencia"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

                  <AutoNumericVue
                      v-model="mountSend"
                      required
                      type="text" inputmode="decimal"
                      :readonly="isDisabledSendCripto2"
                      @input="handleInput2"
                      @blur="handleInput"
                      class="autonumeric_input"
                      :options="json_opciones"
                      :placeholder="'$0.00'"
                  ></AutoNumericVue>


            
            </b-col>

             <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
             style="margin-bottom:5px"
            >

                 <b-alert
              variant="primary"
              show
            >
              <div class="alert-body">
              
                           <p
                class="textLabel"
                style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
              >
                Monto estimado a transferirse: <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Es el monto en valor de criptomoneda se va a enviar.'"
                                  title="Monto a transferirse"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>


              <p style="text-align: center; font-size: 16px; font-weight: bold; margin: 0;"> {{mountTransferencia}} {{currencyLabel}} = ${{mountWithComision}} USD</p>
            
              
              </div>
            </b-alert>



            
         
            </b-col>

              <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

                <b-alert
              variant="danger"
              v-if="nofound"
              show
            >
              <div class="alert-body">
                <p style="margin:0; text-align: left;font-size:12px"> {{errorBalance}}</p>
              
              </div>
            </b-alert>

            </b-col>

            <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
            >

           

              <p
                class="textLabel"
                style="text-align: center;margin-top: 0px;margin-bottom: 10px;"
              >
                Ingrese su código PIN (*): <feather-icon
                                  icon="HelpCircleIcon"
                                  size="16"
                                    v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                  title="Código PIN de seguridad"
                                  style="margin-right: 5px;cursor:pointer;"
                                  /> </p>

              <PincodeInput
                v-model="pin"
                placeholder="-"
                :length="6"
                :autofocus="false"
                :secure="true"
                :disabled="isDisabledSendCripto2"
               
                required
              />

            </b-col>

            <b-col
            cols="6"
              sm="6"
              md="6"
              lg="6"
              xl="6"
            >

              <b-button
                id="btn_atras_send"
                ref="btn_atras_send"
                variant="outline-secondary"
                block
                @click="atrasSend"
              >

              <feather-icon

                  icon="ArrowLeftCircleIcon"
                  size="13"
                  style= "margin-right:10px"
                  />

                Atras

              </b-button>

            </b-col>


            <b-col
            cols="6"
              sm="6"
              md="6"
              lg="6"
              xl="6"
            >

              <b-button
                id="btn_send"
                ref="btn_send"
                type="submit"
                variant="primary"
                block
                :disabled="isDisabledSendCripto"
              >

                Transferir

              </b-button>

            </b-col>

              <b-col
              sm="12"
              md="12"
              lg="12"
              xl="12"
              style="margin-top: 5px;margin-bottom: 5px;"
              v-if="isCompleteAddress"
             >

              <b-alert
              variant="primary"
              show
            >
              <div class="alert-body">
                    <p style="text-align: left;font-size:13px; margin-bottom: 0px;">Monto mínimo de envio:  <b>${{minEnvio}} USD</b></p>
             <p style="text-align: left;font-size:13px; margin-bottom: 0px;">Comisión de envio:  <b>${{comision}} USD</b> </p>
             <p style="text-align: left;font-size:13px; margin-bottom: 0px;">Monto mínimo + comisión:  <b>${{totalMinEnvio}} USD</b> </p>
              
              
              </div>
            </b-alert>

              
         

            </b-col>

       


          </b-row>
        </b-form>

      </div>

    </b-modal>

</template>

<script>
import {
    BAlert, VBTooltip,BSkeleton,BButton,VBPopover, BRow, BCol, BCard, BFormInput, BForm, BInputGroup, BInputGroupPrepend
} from 'bootstrap-vue'

import PincodeInput from 'vue-pincode-input'
    import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
export default {
    components: {
    BButton,
    PincodeInput,
    AutoNumericVue,
    BSkeleton,
    BFormInput,
    BInputGroup, BInputGroupPrepend,
    BRow,
    BCol,
    BForm,
    BAlert,
    BCard
    },
    props: ['idWallet','userId' ,'currency', 'tokenAuth'],
    directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
    },
    data() {

    let currencyLabel="";
    if(this.currency === "BSC"){
    currencyLabel= "BNB"
    }else{
    currencyLabel=this.currency;
    }

   


    return {
        currencyLabel:currencyLabel,
        checkStatus:0,
        json_opciones:{},
        addressEnviar:"",
        loading2:true,
        isDisabledSendCripto:false,
      isDisabledSendCripto2:false,
      showAddress:true,
      balanceCripto:0,
      priceCripto:0,
      pin:"",
      mountTransferencia:"0.0000",
      mountSendRespaldo:0,
      mountSend:null,
      mountWithComision:"0.00",
      isCompleteAddress:false,
      addressRespaldo:"",
      saldoDisponible:0,
      comision:0,
     errorBalance:"",
     nofound:false,
      minEnvio:0,
      totalMinEnvio:0,

    }
    },
    computed: {

    },
    watch: {
    
    },
    mounted() {
      this.json_opciones={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:1000000, modifyValueOnWheel: false };

        this.addressEnviar="";
        this.loading2=true;
    this.isDisabledSendCripto=true;
    this.showAddress=true;

        this.$refs.modalSendCripto.show();

    this.getBalanceSend();


    },
    methods: {

        atrasSend(){

this.showAddress=true;

},


    getBalanceSend(){


       
       const userId_json = { currency: this.currency, userId: this.userId, id: this.idWallet }
    const user_string = JSON.stringify(userId_json)

    const dataEncripted = this.$encryptCyptos.encrypt(user_string)



    this.$httpsCryptos.post('/main/checkBalanceCripto/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
        
      this.loading2=false;
        this.isDisabledSendCripto=false;
       
      if (response.data.code == 200) {



        this.balanceCripto= response.data.balanceCripto;
        this.priceCripto= response.data.priceCripto;

      

          
         
         this.pin="";
          this.addressEnviar="";
           this.mountTransferencia="0.0000";
           this.mountSend="";
            this.mountWithComision="0.00";
            this.addressRespaldo="";





           this.isCompleteAddress= false;



            this.saldoDisponible= Number(Number(this.balanceCripto) * Number(this.priceCripto)).toFixed(2)
         
         
            
         this.checkStatusWalletsSend();

   



      } else {

 


        if (response.data.code == 401) {

            this.$refs.modalSendCripto.hide();

            this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
            })

          
          localStorage.removeItem('userData')



          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        }else{
            this.getBalanceSend();
        }
      }
    }).catch(error => {

 
        this.getBalanceSend();
         

    })                   
        
            

    },

    

    onSubmitSearchAddress(event) {
      event.preventDefault();



      if (this.addressEnviar == '') {

            this.addressRespaldo = "";
              this.isCompleteAddress= false;
              this.nofound=false;
              this.errorBalance="";
                this.mountSend ="";
                this.mountTransferencia="0.0000";
                  this.mountWithComision="0.00";

                  this.$toast.error("El campo del address de la billetera es obligatorio", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })


            /*



            this.isCompleteAddress=true;

            this.mountBuy="";
            this.mountTransferencia="0.0000";
            this.mountWithComision="0.00";

            */

            } else if (this.addressEnviar != this.addressRespaldo) {


            this.addressRespaldo = "";
            this.isDisabledSendCripto2=true;
            this.isDisabledSendCripto= true;
            this.isCompleteAddress=false;
            this.comision=0;
              this.nofound=false;
              this.errorBalance="";
                this.mountSend ="";
                this.mountTransferencia="0.0000";
                  this.mountWithComision="0.00";
            //  this.mountBuy="";
            // this.mountTransferencia="0.0000";
            //this.mountWithComision="0.00";


            const userId_json = { userId: this.userId,  id: this.idWallet, address: this.addressEnviar }
            const user_string = JSON.stringify(userId_json);
            



              const dataEncripted = this.$encryptCyptos.encrypt(user_string)






            document.getElementById('btn_continuar_send').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Comprobando'




            this.$httpsCryptos.post('/main/checkMalicousAddress/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
            
            document.getElementById('btn_continuar_send').innerHTML = 'Siguiente'

            this.isDisabledSendCripto= false;
              this.isDisabledSendCripto2=false;



            if (response.data.code == 200) {
              this.addressRespaldo = this.addressEnviar
              this.comision= response.data.comision;
              this.minEnvio=response.data.minEnvio;
              this.isCompleteAddress=true;

              this.showAddress=false;

              

              if(Number(this.comision) === Number("0")){


                  this.totalMinEnvio=this.minEnvio;

              }else{

                this.totalMinEnvio=Number(this.comision) + Number(this.minEnvio);



                
                
              }

              
            } else {
              this.addressEnviar = ''

              this.$toast.error(response.data.message, {
                position: 'top-right',
                timeout: 3010,
                closeOnClick: true,
                pauseOnFocusLoss: false,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.4,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: 'button',
                icon: true,
                rtl: false,
              })
            }
            }).catch(error => {
            this.addressEnviar = ''

            this.$toast.error("Ocurrió un error inesperado", {
              position: 'top-right',
              timeout: 3010,
              closeOnClick: true,
              pauseOnFocusLoss: false,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.4,
              showCloseButtonOnHover: false,
              hideProgressBar: true,
              closeButton: 'button',
              icon: true,
              rtl: false,
            })
            })
            }else{
            this.showAddress=false;
            }



    },

    onSubmitSendCripto(event) {
      event.preventDefault();

        const userId_json = { userId: this.userId, pin: this.pin, mount: this.mountSend, id: this.idWallet, address: this.addressEnviar }
        const user_string = JSON.stringify(userId_json);

   
    
          

         
           this.isDisabledSendCripto2=true;
           this.isDisabledSendCripto= true;

            const dataEncripted = this.$encryptCyptos.encrypt(user_string)

          document.getElementById('btn_send').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Transferiendo';

            this.$httpsCryptos.post('/main/sendCripto/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
                    this.isDisabledSendCripto2=false;
                    this.isDisabledSendCripto= false;

                  document.getElementById('btn_send').innerHTML = "Transferir";

                  
          
              
                  if (response.data.code == 200) {

            
                    this.pin= "";
                    this.mountSend="";
                    this.addressEnviar="";
                    this.addressRespaldo="";


          
                      

                    this.$refs.modalSendCripto.hide();

                    this.$toast.success(response.data.message, {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                });

                this.$eventBus.$emit('refreshSaldoCripto')


                  } else {

                      this.$toast.error(response.data.message, {
                      position: 'top-right',
                      timeout: 3010,
                      closeOnClick: true,
                      pauseOnFocusLoss: false,
                      pauseOnHover: true,
                      draggable: true,
                      draggablePercent: 0.4,
                      showCloseButtonOnHover: false,
                      hideProgressBar: true,
                      closeButton: 'button',
                      icon: true,
                      rtl: false,
                    })


                    if (response.data.code == 401) {
                      
                      localStorage.removeItem('userData')



                      
                      

                    

                      
                      

                      // Redirect to login page
                      this.$router.push({ name: 'auth-login' })
                    }
                  }
                }).catch(error => {
                  document.getElementById('btn_send').innerHTML = "Transferir";
                  this.isDisabledSendCripto2=false;
                    this.isDisabledSendCripto= false;


            
               
                  this.$toast.error(error, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                  })
                })

 


        },


        handleInput2(value){

            if(value === "" || value === "null" || value === null  ){

                
                

                    this.mountTransferencia="0.0000";
                    this.mountWithComision="0.00";
                    this.nofound= false;
                    this.isDisabledSendCripto=false;



                }else{

                    if(Number(value) < Number(this.totalMinEnvio)){

                    this.nofound= true;
                    this.errorBalance="El monto a enviar debe ser mayor a $"+this.totalMinEnvio+" USD";
                    this.isDisabledSendCripto=true;



                    }else{
                        
                        
                    

                        if(Number(value) > Number(this.saldoDisponible)){

                            this.nofound= true;
                        this.errorBalance="El monto $"+value+" USD supera su saldo de billetera. Recuerde las comisiones adicionales que se cobran.";

                        this.isDisabledSendCripto=true;

                        }else{

                        this.nofound= false;

                            this.mountWithComision= Number(Number(value) - Number(this.comision)).toFixed(2);

                            this.isDisabledSendCripto=false;
                
            
                        let mounTrans=Number(this.mountWithComision)  / Number(this.priceCripto);

                            if(Number(mounTrans.toString().length) > 9){
                                this.mountTransferencia= Number(mounTrans.toString().substring(0, 9));
                            }else{

                            this.mountTransferencia= mounTrans;

                            }


                    }


            


                }

                }

                    },

                    handleInput () {


                    var t = this.mountSend;

                    if(t === "" || t === "null" || t === null  ){

                            this.nofound=false;

                            this.mountTransferencia="0.0000";
                            this.mountWithComision="0.00";


                        }else{

                            if(Number(t) < Number(this.totalMinEnvio)){

                            this.nofound=false;


                            this.mountSend ="";
                            this.mountTransferencia="0.0000";
                            this.mountWithComision="0.00";

                        

                            this.$toast.error("El monto a enviar debe ser mayor a $"+this.totalMinEnvio+" USD", {
                                position: 'top-right',
                                timeout: 3010,
                                closeOnClick: true,
                                pauseOnFocusLoss: false,
                                pauseOnHover: true,
                                draggable: true,
                                draggablePercent: 0.4,
                                showCloseButtonOnHover: false,
                                hideProgressBar: true,
                                closeButton: 'button',
                                icon: true,
                                rtl: false,
                            })

                    }else{



                    

                    if(Number(this.mountSend) > Number(this.saldoDisponible)){



                        this.$toast.error("El monto $"+this.mountSend+" USD supera su saldo de billetera. Recuerde las comisiones adicionales que se cobran.", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    });

                            this.mountSend ="";
                        this.mountTransferencia="0.0000";
                            this.mountWithComision="0.00";
                            this.nofound=false;

                    }else{

                        
                    

                        this.nofound=false;

                        this.mountWithComision= Number(Number(this.mountSend) - Number(this.comision)).toFixed(2);

                    
                        let mounTrans=Number(this.mountWithComision)  / Number(this.priceCripto);

                        if(Number(mounTrans.toString().length) > 9){
                            this.mountTransferencia= Number(mounTrans.toString().substring(0, 9));
                        }else{

                            this.mountTransferencia= mounTrans;

                        }

                        

                    }


                    }

                        }

                    



                    



                    },


    closeModalSend(){
        clearInterval(this.checkStatus);
        this.$eventBus.$emit('reiniciarModalSend')
            
    },
    checkStatusWalletsSend(){


            this.checkStatus = setInterval(() => {
            
            
            
            
            this.$httpsCryptos.post('/main/priceCripto/', { tokenAuth: this.tokenAuth, currency: this.currency}).then(response => {
                
            
                if (response.data.code == 200) {
            
                this.priceCripto= response.data.balance;
            
                } else {
            
            
                if (response.data.code == 401) {
                    
                    localStorage.removeItem('userData')
            
            
            
                    
                    this.$toast.error(response.data.message, {
                    position: 'top-right',
                    timeout: 3010,
                    closeOnClick: true,
                    pauseOnFocusLoss: false,
                    pauseOnHover: true,
                    draggable: true,
                    draggablePercent: 0.4,
                    showCloseButtonOnHover: false,
                    hideProgressBar: true,
                    closeButton: 'button',
                    icon: true,
                    rtl: false,
                    })
            
                    
            
                    
                    
            
                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                }
                }
            }).catch(error => {
                
            })
            
            
            
            }, 30000)
            
            },
                        
                


    },
}
</script>

<style lang="scss">




</style>
                