<template>

    <b-card >
      <modal-alquiler v-if="showModalArriendo" :key="componentKeyModalArriendo" :currency="currency" :user-id="userId" :token-auth="tokenAuth" :id-wallet="idWallet"  />
  
      <b-row v-if="isDisponible"> 

        <b-col v-if="loading" align="center" cols="12" sm="12" md="12" lg="12" xl="12">
          
            <b-row>

                    <b-col  align="center" cols="3" sm="3" md="3" lg="3" xl="3" style="display:flex">

                        <b-skeleton type="avatar" width="30px" height="30px" style="margin:auto" ></b-skeleton>



                        </b-col>

                        <b-col  align="center" cols="9" sm="9" md="9" lg="9" xl="9">

                            <b-alert
                                variant="secondary"
                                show
                            >
                                <div class="alert-body">
                            

                                        
                                    <b-skeleton width="100%" height="10px" style="margin-bottom:10px !important" ></b-skeleton>
                                    <b-skeleton width="100%" height="10px" style="margin-bottom:10px !important" ></b-skeleton>
                                    <b-skeleton width="100%" height="10px" style="margin-bottom:10px !important" ></b-skeleton>
                                    <b-skeleton width="100%" height="10px" style="margin-bottom:10px !important" ></b-skeleton>
          
                            
                                </div>
                            </b-alert>


                        

                            </b-col>

                    </b-row>


            <b-skeleton width="100%" height="40px" style="margin-top:10px;margin-bottom:0px !important" ></b-skeleton>
          

       </b-col>


      <b-col v-else-if="!hasArriendo" align="center" cols="12" sm="12" md="12" lg="12" xl="12">

        <b-row>

            <b-col  align="center" cols="3" sm="3" md="3" lg="3" xl="3" style="display:flex">

                <img  src="/img/rent.svg"  style="width: 60px;margin:auto"/>



                </b-col>

                <b-col  align="center" cols="9" sm="9" md="9" lg="9" xl="9">

                    <b-alert
                        variant="secondary"
                        show
                      >
                        <div class="alert-body">
                     

                                
                            <p style="margin-bottom:0px; font-size:14px;text-align: center; ">¡Arrienda <strong style="font-size:16px">{{ currency }}</strong> <br>por <strong style="font-size:16px">{{ daysArriendoCripto }} días </strong><br>y gana el <strong style="font-size:16px">{{porcentajeArriendoCripto}}%</strong> <br>de lo que arrendaste!</p>
             
                     
                        </div>
                      </b-alert>


                  

                    </b-col>

        </b-row>
       
                
      
          <b-button 
                                                              @click="showModalAlquiler"
                                                              variant="primary"
                                                              class="animacion_button"
                                                              size="md"
                                                              block
                                                              style="margin-top:15px; margin-bottom:10px"
                                                             
                                                            >

                                                            <feather-icon
  
                                                                        icon="BriefcaseIcon"
                                                                        size="14"
                                                                        style= "margin-right:2px"
                                                                        />
                                                   
                                                              Arrendar mis {{ currency }}
  
                                                            </b-button>

                      <b-alert
                        variant="primary"
                        show
                      >
                        <div class="alert-body">
                     

                                
                            <p style="margin-bottom:0px; font-size:12px;text-align: center; ">Recibe comisiones de tus afiliados que arrienden {{ currency }}, hasta en 3 niveles,  <b>{{ comisionArriendoPrimerNivel }}%</b>, <b>{{ comisionArriendoSegundoNivel }}%</b> y <b>{{ comisionArriendoTercerNivel }}%</b> respectivamente.</p>
             
                     
                        </div>
                      </b-alert>

   
      </b-col>

      <b-col v-else align="center" cols="12" sm="12" md="12" lg="12" xl="12">

        <p style="text-align: center; font-style: italic; font-size:12px; margin-bottom:5px">Ganancia total del arriendo</p>
        <p  style=" margin-bottom: 10px;font-size:30px;font-weight: bold;text-align: center;" ><small style="font-size:16px">$</small>{{ ganancia }}<small style="font-size: 9px;">USD</small></p>

        <p style="text-align: center; font-size:12px; margin-bottom:5px"><b><span style="color: red">(*)</span> Monto arrendado: </b>{{ monto }} USDT</p>
        
        <div v-if="showAlert">
          <b-alert
        
        variant="secondary"
        show
      >
        <div class="alert-body">
     

                
            <p style="margin-bottom:0px; font-size:12px;text-align: center; ">El reclamo de la ganancia de este arriendo lo podrás realizar en 30 días, es decir, a partir del <b>{{ dateRetiro }}</b></p>

     
        </div>
      </b-alert>

      <p style="margin:0px; font-size: 12px; font-style: italic"> <span style="color: red">(*)</span> Tus {{ monto }} USDT serán devueltos una vez que reclames la ganancia del arriendo. </p>

        </div>
      

        <b-button 
        v-else
            @click="reclamarArriendo"
            variant="primary"
            size="md"
            block
            style="margin-top:15px; margin-bottom:0px"
            :disabled="isDisabled"
            class="animacion_button"
            id="btn_retirar"
            ref="btn_retirar"
          >

          <feather-icon

                      icon="AwardIcon"
                      size="14"
                      style= "margin-right:2px"
                      />
  
           Reclamar mis ganancias

          </b-button>
             
 
   
       </b-col>
    </b-row>



    <b-row v-else> 




        <b-col align="center" cols="12" sm="12" md="12" lg="12" xl="12">

            <p style="font-weight: bold; text-align: center;font-size: 15px">El arriendo de criptomonedas no está disponible para esta criptomoneda</p>
        
            <img  src="/img/close.svg"  style="width: 80px;"/>




       </b-col>


    </b-row>
                


             
  
                 
  
     </b-card>
      
         
      
      </template>
      
      <script>
      import {
        BButton, BSkeleton, BRow, VBPopover, BCol, BCard, BAlert
      } from 'bootstrap-vue'
      
      import ModalAlquiler from './ModalAlquiler.vue'
      
      export default {
        components: {
          ModalAlquiler,
            BSkeleton,
          BButton,
          BAlert,
          BRow,
          BCol,
          BCard,
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['userId', 'tokenAuth', 'currency', 'idWallet'],
        data() {

            let isDisponible=false;

            if(this.currency === "USDT"){
                isDisponible=true;
            }
       


          return {
            loading:true,
            hasArriendo:false,
            isDisponible:isDisponible,
            dateRetiro:"",
            monto:"",
            ganancia:"",
            currentDay:"",
            daysArriendoCripto:0,
            porcentajeArriendoCripto:0,
            showModalArriendo:false,
           componentKeyModalArriendo:0,
           showAlert:true,
           idArriendo:"",
           isDisabled:false,
           comisionArriendoPrimerNivel:0,
           comisionArriendoSegundoNivel:0,
           comisionArriendoTercerNivel:0,
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

          this.$eventBus.$on('reiniciarModalArriendo', () => {
            this.showModalArriendo=false;
            this.componentKeyModalArriendo += 1
          });

            this.checkArriendo();
      
        },
        methods: {

          reclamarArriendo(){

            if(this.currentDay >= this.dateRetiro){

              this.$swal({

                  html:
                        '<img style="margin-left:15px;width:20px; " src="/img/loading.svg" alt="Loading" /> Obteniendo ganancias, espere',
                  showCloseButton: false,
                  showCancelButton: false,
                  focusConfirm: false,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  showConfirmButton: false,
                  position: 'center',
                  })

              this.isDisabled = true;
           
              document.getElementById('btn_retirar').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Reclamando';
    

                const data_json = { userId: this.userId, id: this.idArriendo }
                const data_string = JSON.stringify(data_json)
             
                const dataEncripted = this.$encryptCyptos.encrypt(data_string)

                
                this.$httpsCryptos.post('/main/reclamarArriendo/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
                      this.$swal.close()

                  
                    if (response.data.code == 200) {

                      
                      this.isDisabled = false;

                      document.getElementById('btn_retirar').innerHTML = 'Reclamar mis ganancias ';
    
                      this.$toast.success(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        });
    

                        this.$eventBus.$emit('refreshSaldoCripto')
                        this.$eventBus.$emit('reiniciarAlquiler')
                  


                    } else {

                   

                      document.getElementById('btn_retirar').innerHTML = 'Reclamar mis ganancias ';
    
                      this.isDisabled = false;
           
                    if (response.data.code == 401) {

                      this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })

                    
                    localStorage.removeItem('userData')


                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                    }else{


                      if (response.data.code == 501) {

                        this.$swal({
                              title: 'Tu perfil debe estar verificado para realizar esta acción',
                              text: `¿Deseas verificar tu cuenta ahora?`,
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonText: 'Verificar cuenta',
                              cancelButtonText: 'No',
                              customClass: {
                                confirmButton: 'btn btn-primary',
                                cancelButton: 'btn btn-outline-secondary ml-1',
                              },
                              buttonsStyling: false,
                            }).then(result => {
                              if (result.value) {

                                this.$router.push({ name: 'perfil', hash: '#verificacion' })

                              }
                            })

                      }

                    }
                    }
                    }).catch(error => {
                      this.isDisabled = false;
           
                      document.getElementById('btn_retirar').innerHTML = 'Reclamar mis ganancias ';

                      this.$toast.error("Ocurrió un error inesperado", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })
    
                        
                    })



            }else{

              this.$toast.error("Podrá realizar el retiro de las ganancias de este arriendo el " + this.dateRetiro, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })

            }

          },

          showModalAlquiler(){
            //this.showModalArriendo=true;

            this.$toast.error("Funcionalidad no disponible por el momento", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })

           
          },

          checkArriendo(){

            this.loading = true;
            this.hasArriendo=false;



                const data_json = { userId: this.userId, currency: this.currency }
 
                    this.$httpsCryptos.post('/main/checkArriendo/', { tokenAuth: this.tokenAuth, datos: data_json }).then(response => {

               
                    if (response.data.code == 200) {
                    this.loading = false;

                    this.hasArriendo= response.data.hasArriendo;
     
                    this.daysArriendoCripto=response.data.daysArriendoCripto;
                    this.comisionArriendoPrimerNivel=response.data.comisionArriendoPrimerNivel;
                    this.comisionArriendoSegundoNivel=response.data.comisionArriendoSegundoNivel;
                    this.comisionArriendoTercerNivel=response.data.comisionArriendoTercerNivel;
                    this.porcentajeArriendoCripto=response.data.porcentajeArriendoCripto;

                    if(this.hasArriendo){

                        //le muestro el balance
                        this.dateRetiro= response.data.dateRetiro;

                        this.monto= response.data.monto;
                        this.ganancia= response.data.ganancia;
                        this.currentDay= response.data.currentDay;
                        

                        if(this.currentDay >= this.dateRetiro){
                          this.idArriendo= response.data.idArriendo;
                          this.showAlert=false;

                        }
           

                    }

                    } else {

            

                    if (response.data.code == 401) {

                        this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })

                    
                    localStorage.removeItem('userData')


                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                    } else {
                        this.checkArriendo();
                    }
                    }
                    }).catch(error => {
                        this.checkArriendo();
                    })
              },
  
        
      
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      