<template>

    <b-card >
      <modal-buy v-if="showModalBuy" :key="componentKeyModalBuy" :currency="currency" :user-id="userId" :token-auth="tokenAuth" :id-wallet="idWallet"  />
  
      <b-row>

        <p v-if="!loading" style="    cursor: pointer; margin: 0px; position: absolute; right: 15px; z-index: 1;" @click="refresh()" > 
                                                            <feather-icon
                                                            icon="RefreshCcwIcon"
                                                            size="16"
                                                            style="margin-right:5px"
                                                            />  </p>


      <b-col align="center" cols="12" sm="12" md="12" lg="12" xl="12" style="height: 80px;">
       
          <p style="margin-bottom:10px; font-size:12px;text-align: center; ">Cantidad para comprar</p>
                      
                      <b-skeleton v-if="loading" width="100%" height="38px" style="margin-bottom:30px !important" ></b-skeleton>
                       
                      <p v-else style=" margin-top: 30px;font-size:25px;font-weight: bold;text-align: center;" ><small style="font-size:16px"></small>{{ maxBuy }}<small style="font-size: 9px;">{{ currency2 }}</small></p>

     
   
      </b-col>

      <b-col align="center" cols="12" sm="12" md="12" lg="12" xl="12">
        <b-skeleton v-if="loading" width="100%" height="40px" style="margin-top:15px;margin-bottom:0px !important" ></b-skeleton>
                       
        <b-button v-else
                                                              @click="showModalBuyCripto"
                                                              variant="primary"
                                                              class="animacion_button"
                                                              size="md"
                                                              block
                                                              style="margin-top:15px; margin-bottom:0px"
                                                             
                                                            >
                                                            <feather-icon
  
                                                              icon="ArrowDownLeftIcon"
                                                              size="14"
                                                              style= "margin-right:2px"
                                                              />
                                                      
                                                              Comprar {{ currency2 }}
  
                                                            </b-button>
   
              </b-col>
    </b-row>
                


             
  
                 
  
                  </b-card>
      
         
      
      </template>
      
      <script>
      import {
        BButton, BSkeleton, BRow, VBPopover, BCol, BCard,
      } from 'bootstrap-vue'
      
      import ModalBuy from './ModalBuy.vue'
      
      export default {
        components: {
         
            BSkeleton,
          BButton,
          ModalBuy,
          BRow,
          BCol,
          BCard,
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['userId', 'tokenAuth', 'currency','idWallet'],
        data() {
      
      
          return {
            loading:true,
            maxBuy:0,
            currency2:this.currency,
            showModalBuy:false,
            componentKeyModalBuy:0,
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

          this.$eventBus.$on('reiniciarModalBuy', () => {
              this.showModalBuy=false;
              this.componentKeyModalBuy += 1
            });

          this.$eventBus.$on('reiniciarMontoCompra', (currency) => {
            this.currency2=currency;
            this.getMontoComprar();

          });

            this.getMontoComprar();
      
        },
        methods: {

          refresh(){
           
            this.getMontoComprar();
          },

          getMontoComprar(){

            this.loading = true;

         
            if(this.currency2 === "BSC"){
                 this.currency2="BNB";
            }

   
              
                    this.$httpsCryptos.post('/main/checkMontoDisponibleBuy/', { tokenAuth: this.tokenAuth, currency: this.currency2 }).then(response => {

               
                    if (response.data.code == 200) {
                    this.loading = false;

                    this.maxBuy= response.data.maxBuy;

                    } else {

             


                    if (response.data.code == 401) {

                      this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })
                    
                    localStorage.removeItem('userData')


                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                    } else {
                        this.getMontoComprar();
                    }
                    }
                    }).catch(error => {
                   this.getMontoComprar();
                    })
                    },
  
                    
            showModalBuyCripto(){


              if(Number(this.maxBuy) <= Number("0")){

                this.$toast.error("Actualmente no existe existe una compra disponible para esta criptomoneda.", {
                  position: 'top-right',
                  timeout: 3010,
                  closeOnClick: true,
                  pauseOnFocusLoss: false,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.4,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: 'button',
                  icon: true,
                  rtl: false,
                })

              }else{

                this.showModalBuy=true;

                

              }
              
      
          }
      
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      