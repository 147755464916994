<template>

   
    <b-modal
          ref="modalArriendo"
          centered
          hide-footer
          :title="title"
          size="sm"
          @hidden="closeModalArriendo"
        >
          <div class="d-block text-center">
    
            <b-form
              autocomplete="off"
              @submit.prevent="onSubmitArriendo"
            >
    
              <b-row>
    
                  <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
    
                      <b-alert
                    variant="secondary"
                    show
                    style="margin-bottom: 10px !important;"
                  >
                    <div class="alert-body">
                      
                             <p style="text-align: center;font-weight: bold;">Saldo de billetera</p>
    
                             <div  v-if="loading2">
                              <b-skeleton  height="65px" width="100%"  style="margin:0px"></b-skeleton>
    
    
                              </div>
    
                        
                            <div  v-else>
    
    
                                <p class="saldoDisponible" style="margin-bottom: -8px;">{{saldoDisponible }}<small style="font-weight: bold; font-size:10px"> USD</small></p>
    
                            <p style="text-align: center; font-size: 15px;">= {{balanceCripto}}<small style="font-weight: bold; font-size:10px"> {{currencyLabel}}</small></p>
                      
                              </div>
                    
                    </div>
                  </b-alert>
    
                  </b-col>
    
                     <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
                
                <p
                    class="textLabel"
                    style="text-align: center;margin-top: 0px;margin-bottom: 5px;"
                  >
                    Monto a arrendar (*): <feather-icon
                                      icon="HelpCircleIcon"
                                      size="16"
                                        v-b-popover.hover.bottom.html="'Es el monto en dólares (USD) que pondrá en arriendo.'"
                                      title="Monto de arriendo"
                                      style="margin-right: 5px;cursor:pointer;"
                                      /> </p>
                </b-col>
    
    
    
    
    
                  <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                  style="margin-bottom:5px"
                >
    
                  <AutoNumericVue
                    v-model="mountArriendo"
                    required
                    type="text" inputmode="decimal"
                    :disabled="isDisableArriendo2"
                    :autofocus="false"
                    @input="typeArriendo2"
                    @blur="typeArriendo"
                    class="autonumeric_input"
                    :options="json_opciones_arriendo"
                    :placeholder="'$0.00'"
                ></AutoNumericVue>
    
                
                 
                <p style="font-size: 14px; margin-bottom:0;font-style: italic;">= {{mountArriendoSaldo}} {{currencyLabel}}</p>
    
                  
                
                </b-col>
    
                <b-col
                          sm="12"
                          md="12"
                          lg="12"
                          xl="12"
                        >
    
                    <b-alert
                        variant="primary"
                        show
                        style="margin-top:0px"
                      >
                        <div class="alert-body">
                          <p style=" text-align: center;font-size:12px">Monto máximo disponible para arrendar</p>
                          <b-skeleton v-if="loading2" style="margin-bottom:0px" width="100%" height="20px"  ></b-skeleton>
                          <p v-else style="text-align: center;font-size:25px;font-weight: bold; margin-bottom: 0px;">${{maxArriendoCriptoDay}} <span style="font-size:12px">USD</span></p>
                          
                      
                        
                        </div>
                      </b-alert>
    
                </b-col>
    
    
    
                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
    
               
    
                  <p
                    class="textLabel"
                    style="text-align: center;margin-bottom: 10px;"
                  >
                    Ingrese su código PIN (*): <feather-icon
                                      icon="HelpCircleIcon"
                                      size="16"
                                        v-b-popover.hover.bottom.html="'Si aún no tiene registrado un código PIN o se le olvidó cual era, puede dirigirse a <b>Mi Perfil > Código PIN de seguridad </b> para registrarlo o modificarlo'"
                                      title="Código PIN de seguridad"
                                      style="margin-right: 5px;cursor:pointer;"
                                      /> </p>
    
                  <PincodeInput
                    v-model="pin"
                    placeholder="-"
                    :length="6"
                    :autofocus="false"
                    :secure="true"
                    required
                  />
    
                </b-col>
    
                <b-col
                  sm="12"
                  md="12"
                  lg="12"
                  xl="12"
                >
    
                  <b-button
                    id="btn_arriendo"
                    ref="btn_arriendo"
                    type="submit"
                    variant="primary"
                    block
                    :disabled="isDisableArriendoCripto"
                  >
    
                    Arrendar {{ currencyLabel }}
    
                  </b-button>
    
                </b-col>

                <b-col
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"

                        style="margin-top:10px"
                        >

                        <b-alert
                        variant="primary"
                        show
                        >
                        <div class="alert-body">
                            <p style="margin:0; text-align: left;font-size:12px"> El monto mínimo para realizar un arriendo es de $<b>{{ minArriendoCripto }} USD</b></p>
                           
                            <p style="margin:0; text-align: left;font-size:12px"> <b>Nota: </b> No podrá hacer uso del monto arrendado durante {{ daysArriendoCripto }} días. El monto arrendado se le devolverá en el mismo día en que realize su reclamación de ganancias.</p>
                        </div>
                        </b-alert>

                        </b-col>
    
            
    
           
    
    
              </b-row>
            </b-form>
    
          </div>
    
        </b-modal>
    
         </template>
            
            <script>
            import {
              BAlert, VBTooltip,BSkeleton,BButton,VBPopover, BRow, BCol, BCard, BFormInput, BForm
            } from 'bootstrap-vue'
    
            import PincodeInput from 'vue-pincode-input'
            import AutoNumericVue from 'autonumeric-vue/src/components/AutoNumericVue';
            export default {
              components: {
                BButton,
                PincodeInput,
                AutoNumericVue,
                BSkeleton,
                BFormInput,
                BRow,
                BCol,
                BForm,
                BAlert,
                BCard
              },
              props: ['idWallet','userId' ,'currency', 'tokenAuth'],
                directives: {
                'b-popover': VBPopover,
                'b-tooltip': VBTooltip,
              },
              data() {
        
                let currencyLabel="";
                if(this.currency === "BSC"){
                currencyLabel= "BNB"
                }else{
                currencyLabel=this.currency;
                }
            
                return {
    
                    loading2:true,
                    isDisableArriendoCripto:false,
                   isDisableArriendo2:false,
                   balanceCripto:0,
                   currencyLabel:currencyLabel,
                   priceCripto:0,
                   saldoDisponible:0,
                   maxArriendoCriptoDay:0,
                   daysArriendoCripto:0,
                   pin:"",
                    mountArriendoSaldo: "0.0000",
                    minArriendoCripto:0,
                   json_opciones_arriendo:{},
                   checkStatus:0,
                   mountArriendo:"",
                   title:"",
                }
              },
              computed: {
            
              },
              watch: {
               
              },
              mounted() {
                this.loading2=true;
                this.isDisableArriendoCripto=true;
                this.isDisableArriendo2=true;

                this.title="Arriendo de " + this.currencyLabel;
        
                this.$refs.modalArriendo.show();
    
                this.getBalanceArriendo();
        
            
              },
              methods: {
    
                getBalanceArriendo(){
    
                        const userId_json = { currency: this.currency, id: this.idWallet }
                        const user_string = JSON.stringify(userId_json)
                  
                        const dataEncripted = this.$encryptCyptos.encrypt(user_string)
    
                        this.$httpsCryptos.post('/main/checBalancekArriendo/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
                            
    
                       
                        if (response.data.code == 200) {
                            this.loading2=false;
                            this.isDisableArriendoCripto=false;
                            this.isDisableArriendo2=false;
                            this.balanceCripto= response.data.balanceCripto;
                            this.priceCripto= response.data.priceCripto;
                     
                            this.maxArriendoCriptoDay= response.data.maxArriendoCriptoDay;
                            
                            this.minArriendoCripto= response.data.minArriendoCripto;
                            this.daysArriendoCripto=response.data.daysArriendoCripto;
                            
                            
                            this.pin="";
                        
                            this.mountArriendoSaldo="0.0000";
                            this.mountArriendo="";
    
    
    
                        
                
                                this.json_opciones_arriendo={allowDecimalPadding: "false", currencySymbol: "$", decimalCharacterAlternative: ",",decimalCharacter:".",   minimumValue: 0, maximumValue:this.maxArriendoCriptoDay, modifyValueOnWheel: false };
    
    
    
                                this.saldoDisponible= Number(Number(this.balanceCripto) * Number(this.priceCripto)).toFixed(2);
                            
                         
                        
                            this.checkStatusWalletsArriendo();
                        
    
    
    
                        } else {
    
                      
    
    
                            if (response.data.code == 401) {
    
                                this.$refs.modalArriendo.hide();
    
                   
    
                                this.$toast.error("1) "+response.data.message, {
                            position: 'top-right',
                            timeout: 3010,
                            closeOnClick: true,
                            pauseOnFocusLoss: false,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.4,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: 'button',
                            icon: true,
                            rtl: false,
                        })
    
                            
                            localStorage.removeItem('userData')

    
                            // Redirect to login page
                            this.$router.push({ name: 'auth-login' })
                            }else{
                                this.getBalanceArriendo();
                            }
                        }
                        }).catch(error => {
    
                          this.getBalanceArriendo();
    
    
                        })
    
                },
        
                closeModalArriendo(){
                  clearInterval(this.checkStatus);
                  this.$eventBus.$emit('reiniciarModalArriendo')
                        
                },
                checkStatusWalletsArriendo(){
    
     
    this.checkStatus = setInterval(() => {
    
    
    
    
       this.$httpsCryptos.post('/main/priceCripto/', { tokenAuth: this.tokenAuth, currency: this.currency}).then(response => {
           
    
         if (response.data.code == 200) {
    
           this.priceCripto= response.data.balance;
    
         } else {
    
    
           if (response.data.code == 401) {
             
             localStorage.removeItem('userData')
    
    
    
             
             this.$toast.error("2) "+response.data.message, {
               position: 'top-right',
               timeout: 3010,
               closeOnClick: true,
               pauseOnFocusLoss: false,
               pauseOnHover: true,
               draggable: true,
               draggablePercent: 0.4,
               showCloseButtonOnHover: false,
               hideProgressBar: true,
               closeButton: 'button',
               icon: true,
               rtl: false,
             })
    
             
    
             
             
    
             // Redirect to login page
             this.$router.push({ name: 'auth-login' })
           }
         }
       }).catch(error => {
         
       })
    
    
       
       }, 30000)
    
    },
                
        onSubmitArriendo(event){
    
    event.preventDefault();
    
     const userId_json = { userId: this.userId, pin: this.pin, mount: this.mountArriendo, id: this.idWallet }
     const user_string = JSON.stringify(userId_json);
       
    
      
        this.isDisableArriendoCripto=true;
        this.isDisableArriendo2=true;
    
    
         const dataEncripted = this.$encryptCyptos.encrypt(user_string)
    
       document.getElementById('btn_arriendo').innerHTML = '<img style="margin-left:15px " src="/img/loading2.svg" alt="Loading" />Transferiendo';
    
         this.$httpsCryptos.post('/main/arriendoCripto/', { tokenAuth: this.tokenAuth, datos: dataEncripted }).then(response => {
                 this.isDisableArriendoCripto=false;
                 this.isDisableArriendo2=false;
    
               document.getElementById('btn_arriendo').innerHTML = "Arrendar " +  this.currencyLabel;
    
               
       
           
               if (response.data.code == 200) {
    
         
                 this.pin= "";
                 this.mountArriendo="";
    
    
       
                   
    
                 this.$refs.modalArriendo.hide();
    
                 this.$toast.success(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        });
    

             this.$eventBus.$emit('refreshSaldoCripto')
             this.$eventBus.$emit('reiniciarAlquiler')
    
               } else {
    
                   this.$toast.error("3) "+response.data.message, {
                   position: 'top-right',
                   timeout: 3010,
                   closeOnClick: true,
                   pauseOnFocusLoss: false,
                   pauseOnHover: true,
                   draggable: true,
                   draggablePercent: 0.4,
                   showCloseButtonOnHover: false,
                   hideProgressBar: true,
                   closeButton: 'button',
                   icon: true,
                   rtl: false,
                 })
    
    
                 if (response.data.code == 401) {
                   
                   localStorage.removeItem('userData')
    

                   // Redirect to login page
                   this.$router.push({ name: 'auth-login' })
                 }
               }
             }).catch(error => {
    
               this.$toast.error("Ocurrió un error inesperado", {
                 position: 'top-right',
                 timeout: 3010,
                 closeOnClick: true,
                 pauseOnFocusLoss: false,
                 pauseOnHover: true,
                 draggable: true,
                 draggablePercent: 0.4,
                 showCloseButtonOnHover: false,
                 hideProgressBar: true,
                 closeButton: 'button',
                 icon: true,
                 rtl: false,
               })
             })
    
    },
    
        typeArriendo(){
    
                var t = this.mountArriendo;
                
                
                
                    if(t === "" || t === "null" || t === null || t===0  ){
                
                
                
                    this.mountArriendoSaldo="0.0000";
                    this.mountArriendo="";
                    
                
                
                
                
                    }else{
                
                    
                
                    
                
                        if(Number(t) < Number(this.minArriendoCripto)){
                
                    
                
                
                        this.mountArriendo ="";
                        this.mountArriendoSaldo="0.0000";
                
                
                        this.$toast.error("El monto para arrendar debe ser mayor a $"+this.minArriendoCripto+" USD", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        })
                
                
                
                    }else{
                
                
                
                
                    
                
                        // this.mountSend = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
                
                
                        if(Number(t) > Number(this.saldoDisponible)){
                
                
                
                
                
                        
                                this.mountArriendo ="";
                
                                
                                this.mountArriendoSaldo="0.0000";
                
                        this.$toast.error("El monto $"+t+" USD supera su saldo de billetera.", {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                        })
                
                        }else{
                
                        
                
                
                        
                            let mounTrans=Number(t)  / Number(this.priceCripto);
                
                            if(Number(mounTrans.toString().length) > 9){
                                this.mountArriendoSaldo= Number(mounTrans.toString().substring(0, 9));
                            }else{
                
                                this.mountArriendoSaldo= mounTrans;
                
                            }
                
                            
                
                        }
                
                    
                    }
                
                    }
                
                
                
                
                
                
                },
    
                typeArriendo2(value){
        
    
        if(value === "" || value === "null" || value === null  || value === 0){
    
           
             
    
             this.mountArriendoSaldo="0.0000";
    
           
              this.isDisableArriendoCripto=false;
    
    
    
    
           }else{
    
    
              if(Number(value) < Number(this.minArriendoCripto)){
    
          
               this.isDisableArriendoCripto=true;
    
               }else{
    
                  if(Number(value) > Number(this.saldoDisponible)){
    
                   
                    this.isDisableArriendoCripto=true;
    
                 }else{
    
                    this.isDisableArriendoCripto=false;
                       
                       let mounTrans=Number(value)  / Number(this.priceCripto);
    
                       if(Number(mounTrans.toString().length) > 9){
                           this.mountArriendoSaldo= Number(mounTrans.toString().substring(0, 9));
                       }else{
    
                         this.mountArriendoSaldo= mounTrans;
    
                       }
    
                 }
    
                  
    
    
    
               }
    
      
    
        
    
    
    
           
    
        
    
    
           }
    
    },
            
                
            
            
              },
            }
            </script>
            
            <style lang="scss">
            
        
            
            
            </style>
            