<template>

    <b-card >

      <b-row>
        <b-col align="center" cols="12" sm="12" md="12" lg="12" xl="12">

          <p style="text-align: center;font-size:12px; margin:5px">Límite mensual para comprar</p>
          <b-skeleton v-if="loading" width="100%" height="70px" style="margin-bottom:10px !important" ></b-skeleton>
          <vue-apex-charts
                    v-else
                type="radialBar"
                height="100"
                        width="100"
                :options="goalOverviewRadialBar"
                :series="series"
                />

        </b-col>

        <b-col cols="5" sm="5" md="5" lg="5" xl="5">
          <b-skeleton v-if="loading" width="100%" height="20px" style="margin-bottom:0px !important" ></b-skeleton>
          <p v-else style="text-align: right;margin-bottom:0px; font-size:12px">$ {{ limiteGastado }} <small style="font-size:10px">USD</small></p>
            

          </b-col>

          <b-col cols="2" sm="2" md="2" lg="2" xl="2">

            <p style="text-align: center;margin-bottom:0px; font-size:14px">/</p>


            </b-col>


            <b-col cols="5" sm="5" md="5" lg="5" xl="5">
              <b-skeleton v-if="loading" width="100%" height="20px" style="margin-bottom:0px !important" ></b-skeleton>
  
              <p v-else style="text-align: left;margin-bottom:0px; font-size:12px">$ {{ limiteMaximo }} <small style="font-size:10px">USD</small></p>
            

            </b-col>
      </b-row>
           
     
                      
  
                  </b-card>
      
         
      
      </template>
      
      <script>
      import {
        BButton, BSkeleton, BRow, VBPopover, BCol, BCard,
      } from 'bootstrap-vue'
      
      import VueApexCharts from 'vue-apexcharts'
      
      export default {
        components: {
         
            BSkeleton,
          BButton,
          VueApexCharts,
          BRow,
          BCol,
          BCard,
        },
        directives: {
          'b-popover': VBPopover,
        },
        props: ['userId', 'tokenAuth', 'currency'],
        data() {
            const $strokeColor = '#f0f2f5'
        const $textHeadingColor = '#00838c'
        const $goalStrokeColor2 = '#00838c'
            
          return {
            loading:true,
            limiteMaximo:0,
            limiteGastado:0,
            currency2:this.currency,
      series:[],
      goalOverviewRadialBar: {
        chart: {
          height: 300,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: false,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '50%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '70%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '14px',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ["#00838c"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 0,
          },
        },
      },
      
          }
        },
        computed: {
      
        },
        watch: {
      
        },
        mounted() {

         this.$eventBus.$on('reiniciarCompraByMes', (currency) => {
            this.currency2=currency;
            this.getLimites();

          });

            this.getLimites();
      
         
        },
        methods: {

            getLimites(){
                this.loading = true;

                const data_json = { userId: this.userId, currency: this.currency2 }
    
              
                    this.$httpsCryptos.post('/main/getCompraByMes/', { tokenAuth: this.tokenAuth, datos: data_json }).then(response => {

            
                    if (response.data.code == 200) {
                    this.loading = false;

                    this.limiteMaximo= response.data.limiteMaximo;
                    this.limiteGastado= response.data.limiteGastado;
                    this.series=[Number(response.data.porcentaje)]

                    } else {

                  


                    if (response.data.code == 401) {

                        this.$toast.error(response.data.message, {
                        position: 'top-right',
                        timeout: 3010,
                        closeOnClick: true,
                        pauseOnFocusLoss: false,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.4,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: 'button',
                        icon: true,
                        rtl: false,
                    })
                    
                    localStorage.removeItem('userData')


                    // Redirect to login page
                    this.$router.push({ name: 'auth-login' })
                    } else {
                    this.getLimites();
                    }
                    }
                    }).catch(error => {
                    this.getLimites();
                    })
                    },
  
           
         
      
        },
      }
      </script>
      
      <style lang="scss">
      
      
      
      </style>
      