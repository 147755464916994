<template>

<div>


  <address-wallet v-if="showWalletAddress" :key="componentKeyAddressWallet" :currency="currency" :address="address" :id-wallet="idWallet"  />
  <modal-transferir v-if="showModalTransferir" :key="componentKeyModalTransferir" :currency="currency" :user-id="userId" :token-auth="tokenAuth" :id-wallet="idWallet"  />
  <modal-send v-if="showModalSend" :key="componentKeyModalSend" :currency="currency" :user-id="userId" :token-auth="tokenAuth" :id-wallet="idWallet"  />


     <div class="card" >


          <div class="card-body card_body_saldos">
          <b-row>

         

               <b-col cols="11" sm="11" md="11" lg="11" xl="11">

           

                 </b-col>

  

         <b-col cols="1" sm="1" md="1" xl="1" lg="1" style="z-index: 1;">

          <b-skeleton v-if="loading" style="margin-bottom: 5px;" height="20px" width="20px"></b-skeleton>


                        
                      <feather-icon
                      v-else
                        icon="RefreshCcwIcon"
                        size="18"
                        @click="refresh"
                        style="cursor:pointer;color:#fff;    position: absolute;right: 5px;"
                        />

         
         </b-col>


         <b-col v-if="loading" cols="7" sm="7" md="7" lg="7" xl="7" style="margin-top:0px">

          <b-skeleton height="10px" width="50%"  style="margin-bottom: 5px;"></b-skeleton>
           <b-skeleton height="33px" style="margin-bottom: 5px;" ></b-skeleton>

          </b-col>

         <b-col v-else cols="7" sm="7" md="7" lg="7" xl="7" style="margin-top:10px">
           
                   
                    

                   <p v-if="isBalanceCripto"
                            class="saldo_txt"
            
                            >
                            Saldo en {{currencyLabel}}<feather-icon
                                icon="HelpCircleIcon"
                                size="16"
                                v-b-popover.hover.bottom.html="'Este saldo corresponde a la cantidad de criptomonedas que posee en su billetera.'"
                                title=" Saldo en criptomoneda"
                                style="margin-left: 5px;cursor:pointer;"
                                /> </p>

                      <p v-if="isBalanceUSD"
                            class="saldo_txt"

            
                            >
                            Saldo en dólares<feather-icon
                                icon="HelpCircleIcon"
                                size="16"
                                v-b-popover.hover.bottom.html="'Este saldo corresponde a la cantidad en dólares de criptomonedas que posee en su billetera.'"
                                title=" Saldo en dólares"
                                style="margin-left: 5px;cursor:pointer;"
                                /> </p>

                      <p v-if="isPrecioCripto"
                            class="saldo_txt"
            
                            >
                            Precio criptomoneda<feather-icon
                                icon="HelpCircleIcon"
                                size="16"
                                v-b-popover.hover.bottom.html="'Corresponde al precio de mercado actual de la criptomoneda'"
                                title="Valor de criptomoneda"
                                style="margin-left: 5px;cursor:pointer;"
                                /> </p>

          

                    <p  class="saldo2_txt"><span v-if="currencyUSD" style="font-size:25px">$</span>{{balance}}</p>

         </b-col>

         <b-col  v-if="loading" cols="5" sm="5" md="5" xl="5" lg="5" style="margin-top:0px;">
          <b-skeleton width="100%" height="45px" style="margin-bottom: 5px;"></b-skeleton>

        </b-col>


          <b-col  v-else cols="5" sm="5" md="5" xl="5" lg="5" style="margin-top:30px;">

  

                       <b-form-select
                        v-model="optionSaldo"
                        :options="options"
                        @change="onChange($event)"
                        style="margin:auto"
                        size="sm"
                        />


                   </b-col>

            </b-row>

      </div>

        <b-card-footer>

            <b-row>

                  <b-col cols="4" sm="4" md="4" lg="4" xl="4" align="center" >

                     <b-link @click="enviarCrypto()" v-if="isMobile" class="icon_dashboard"> 


                   <b-avatar
               
                    size="40"
                    variant="light-primary"
                    style="cursor:pointer;">
                 
                     <span class="d-flex align-items-center">
                 
                      <feather-icon
                              icon="ArrowUpRightIcon"
                              size="25"
                              class="color_icon"
                              />
                      
                    </span>

                    </b-avatar>

                    <p style="font-size:10px; margin:0px;">Enviar</p>
                 
                  </b-link>

                  <b-link @click="enviarCrypto()" v-else class="icon_dashboard"> 


                      <b-avatar
                      v-b-tooltip.hover.bottom="'Enviar criptomonedas'"
                      size="40"
                      variant="light-primary"
                      style="cursor:pointer;">

                        <span class="d-flex align-items-center">
                     
                          <feather-icon
                              icon="ArrowUpRightIcon"
                              size="25"
                              class="color_icon"
                              />
                        
                      </span>

                      </b-avatar>

                      <p style="font-size:10px; margin:0px;">Enviar</p>
                      </b-link>

                </b-col>

                <b-col cols="4" sm="4" md="4" lg="4" xl="4" align="center" >

                     <b-link @click="recibirCrypto()" v-if="isMobile" class="icon_dashboard"> 


                        <b-avatar
                        
                          size="40"
                          variant="light-primary"
                          style="cursor:pointer;">
                      
                          <span class="d-flex align-items-center">

                            <feather-icon
                              icon="ArrowDownLeftIcon"
                              size="25"
                              class="color_icon"
                              />

                 
                            
                          </span>

                          </b-avatar>

           
                          <p style="font-size:10px; margin:0px;">Recibir</p>

                  </b-link>

                  <b-link @click="recibirCrypto()" v-else class="icon_dashboard"> 


                    <b-avatar
                    v-b-tooltip.hover.bottom="'Recibir criptomonedas a mi billetera'"
                    size="40"
                    variant="light-primary"
                    style="cursor:pointer;">

                      <span class="d-flex align-items-center">
                        <feather-icon
                              icon="ArrowDownLeftIcon"
                              size="25"
                              class="color_icon"
                              />
                      
                    </span>

                    </b-avatar>

                    <p style="font-size:10px; margin:0px;">Recibir</p>

                    </b-link>

                </b-col>

                 <b-col cols="4" sm="4" md="4" lg="4" xl="4" align="center" >

                     <b-link @click="transferir()" v-if="isMobile" class="icon_dashboard"> 


                   <b-avatar
                    size="40"
                    variant="light-primary"
                    style="cursor:pointer;">
                 
                     <span class="d-flex align-items-center">
                       <feather-icon
                        icon="RepeatIcon"
                        size="25"
                        class="color_icon"
                      />
                      
                    </span>

                    </b-avatar>

                    <p style="font-size:10px; margin:0px;">Convertir a USD</p>

                 
                  </b-link>

                  <b-link @click="transferir()" v-else class="icon_dashboard"> 


                    <b-avatar
                    size="40"
                    v-b-tooltip.hover.bottom="'Convertir criptomonedas a USD'"
                    variant="light-primary"
                    style="cursor:pointer;">

                      <span class="d-flex align-items-center">
                        <feather-icon
                        icon="RepeatIcon"
                        size="25"
                        class="color_icon"
                      />
                      
                    </span>

                    </b-avatar>

                    <p style="font-size:10px; margin:0px;">Convertir a USD</p>


                    </b-link>

                </b-col>





            </b-row>



        </b-card-footer>

</div>


</div>

     

</template>

<script>
import {
  BButton,BCardFooter,BAvatar,  VBPopover,BSkeleton,BAlert,BFormSelect, VBTooltip, BRow, BCol, BCard,BDropdown,BDropdownItem,BLink,
} from 'bootstrap-vue'

import AddressWallet from './AddressWallet.vue'
import ModalTransferir from './ModalTransferir.vue'

import ModalSend from './ModalSend.vue'
export default {
  components: {
    BButton,
    BAvatar,

    ModalSend,
    BCardFooter,
    ModalTransferir,
    AddressWallet,
    BSkeleton,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BFormSelect,
    BAlert,
    BDropdownItem,
    BLink,
    VBPopover,
  },
    directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  props: ['userId','tokenAuth', 'idWallet','address' ,'currency'],
  data() {

 let currencyLabel="";
if(this.currency === "BSC"){
  currencyLabel= "BNB"
}else{
  currencyLabel=this.currency;
}
  

    return {

      loading: true,

      currencyLabel:currencyLabel,
      currencyUSD:true,


     balance:0,


     isMobile:this.$isMobile,
  
      isBalanceUSD:true,
      isPrecioCripto:false,
      isBalanceCripto:false,
   

      showWalletAddress:false,
      componentKeyModalTransferir:0,


      componentKeyModalSend:0,
      showModalTransferir:false,
   
      showModalSend:false,
    
      optionSaldo: "USD",
      componentKeyAddressWallet:0,


      options: [
        { value: "USD", text: 'Saldo USD' },
        { value: 'Precio', text: 'Precio cripto' },
        { value: 'Cripto', text: 'Saldo Cripto' },
      ],

    }
  },
  computed: {

  },
  watch: {


  },
  mounted() {



    this.getBalances("USD");



          this.$eventBus.$on('refreshSaldoCripto', () => {
      this.refresh()

          });     
          
          

          this.$eventBus.$on('reiniciarModalSend', () => {
      this.showModalSend=false;
      this.componentKeyModalSend += 1
    });

    



    this.$eventBus.$on('reiniciarModalTransferir', () => {
      this.showModalTransferir=false;
      this.componentKeyModalTransferir += 1
    });
          
    this.$eventBus.$on('reiniciarAddressWallet', () => {
      this.showWalletAddress=false;
      this.componentKeyAddressWallet += 1
    });
    


  },
  methods: {
  
    recibirCrypto(){
        this.showWalletAddress=true;
    },


   
    getBalances(opcion){

      const userId_json = { id: this.idWallet, currency: this.currency, type: opcion }
      this.loading = true;


    this.$httpsCryptos.post('/main/getBalances/', { tokenAuth: this.tokenAuth, datos: userId_json }).then(response => {

     

      if (response.data.code == 200) {

        this.loading = false;
        this.balance= response.data.balance;
     
      
      } else {

       


        if (response.data.code == 401) {

          this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })
          
          localStorage.removeItem('userData')


          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
          this.getBalances(opcion);
        }
      }
    }).catch(error => {
         this.getBalances(opcion);
    })

    },

   
   


   
    onChange(opcion){


    switch(opcion) {
        case "USD":
          this.currencyUSD=true;
         this.isBalanceUSD=true;
         this.isPrecioCripto=false;
          this.isBalanceCripto=false;
          this.$eventBus.$emit('reiniciarTransaccionesCripto')
         this.getBalances("USD");


        break;

        case "Precio":
           this.currencyUSD=true;
         this.isBalanceUSD=false;
         this.isPrecioCripto=true;
         this.isBalanceCripto=false;
         this.getPrecioCripto();


        break;

         default: //es valor en criptomoneda
          this.currencyUSD=false;
         this.isBalanceUSD=false;
         this.isPrecioCripto=false;
         this.isBalanceCripto=true;
         this.$eventBus.$emit('reiniciarTransaccionesCripto')
         this.getBalances("Cripto");


        break;

    }

      

    },
    getPrecioCripto(){

    this.loading=true;

    this.$httpsCryptos.post('/main/priceCripto/', { tokenAuth: this.tokenAuth, currency: this.currency }).then(response => {
        

      if (response.data.code == 200) {
        this.loading=false;
   
        this.balance= response.data.balance;
      

      
      } else {

        this.$toast.error(response.data.message, {
            position: 'top-right',
            timeout: 3010,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.4,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: 'button',
            icon: true,
            rtl: false,
          })


        if (response.data.code == 401) {
          
          localStorage.removeItem('userData')



          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        } else {
          this.getPrecioCripto();
        }
      }
    }).catch(error => {
         this.getPrecioCripto();
    })

    },


    
  
    transferir(){

      this.showModalTransferir=true;


     

   },

     enviarCrypto(){

      this.showModalSend=true;

    

   },


    refresh() {


    switch(this.optionSaldo) {
        case "USD":
           this.currencyUSD=true;
         this.isBalanceUSD=true;
         this.isPrecioCripto=false;
          this.isBalanceCripto=false;
          this.$eventBus.$emit('reiniciarTransaccionesCripto')
         this.getBalances("USD");


        break;

        case "Precio":
           this.currencyUSD=true;
         this.isBalanceUSD=false;
         this.isPrecioCripto=true;
         this.isBalanceCripto=false;
         this.$eventBus.$emit('reiniciarTransaccionesCripto')
         this.getPrecioCripto();


        break;

         default: //es valor en criptomoneda
            this.currencyUSD=false;
         this.isBalanceUSD=false;
         this.isPrecioCripto=false;
         this.isBalanceCripto=true;
         this.$eventBus.$emit('reiniciarTransaccionesCripto')
         this.getBalances("Cripto");


        break;

    
      

    }

    }

  },
}
</script>

<style lang="scss">


</style>
