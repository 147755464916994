<template>


<b-row :key="componentKeyWalletCripto">

  <div id="overlay" ref="overlay" @click="off()"></div>
    


    <b-col cols="12" sm="12" md="12" lg="3" xl="3"> 

         <balances   :key="componentKeyBalanceCripto" :currency="currency" :user-id="userId" :token-auth="tokenAuth" :id-wallet="idWallet" :address="address"  />
    
         <alquiler   :key="componentKeyAlquiler" :currency="currency" :user-id="userId" :token-auth="tokenAuth" :id-wallet="idWallet" />
    

                  <vue-bottom-dialog v-if="dialogMobile" :indentColor="indentColor" v-model="dialogMobile" :height="280" :topRadius="radius"  :overlayColor="overlay">
                    <b-row>

                      <b-col cols="12" sm="12" md="12" lg="12" xl="12"> 

                          <h4 style="margin: 0;" class="font-weight-bolder">
                            Otras criptomonedas
                            <hr>

                          </h4>

                          </b-col>

                    <div class="opciones_cripto">
                              <label for="USDT"> <img style="width: 25px;margin-right: 10px;" alt="USDT" src="/img/usdt.svg"> Tron TRC20 - USDT</label>
                            <input id="USDT"  type="radio" @click="onChange($event)" :checked="checkedUSDT" value="USDT" name="radio" />
                        </div>
                       

                     

                           <div class="opciones_cripto">
                         <label for="BNB"> <img style="width: 25px;margin-right: 10px;" alt="BNB" src="/img/bsc.svg"> Binance - BNB</label>
                       <input id="BNB"  type="radio" @click="onChange($event)"  :checked="checkedBSC"  value="BSC" name="radio" />
                        </div>
                      

                      </b-row>    
                </vue-bottom-dialog>



      



    </b-col>


    <b-col cols="12" sm="12" md="12" lg="9" xl="9"> 

      
      <carousel :autoplay="false" :dots="false" :stagePadding="stagePadding" :nav="false" :items="3" :margin="margin" :responsive="{0:{items:1,nav:false},600:{items:2,nav:false},1080:{items:3,nav:false},1280:{items:3,nav:false}}">
  
      

        <compra-disponible  :id-wallet="idWallet" :user-id="userId" :token-auth="tokenAuth" :currency="currency" />
        <compra-by-mes   :user-id="userId" :token-auth="tokenAuth" :currency="currency" />
        <limite-diario-conversion   :user-id="userId" :token-auth="tokenAuth" :currency="currency" />

    </carousel>

      

        <b-card >

         

                       <transacciones    :key="componentKeyTransaccionesCripto" :currency="currency" :user-id="userId" :token-auth="tokenAuth" :id-wallet="idWallet" />
    




                  </b-card>



    </b-col>

     
       
<a v-if="isMobile"  @click="dialogMobile = true" class="float" >

  <svg width="30" class="my-float" height="30"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 458.531 458.531" style="enable-background:new 0 0 458.531 458.531"><g id="XMLID_830_"><g><g><path d="M336.688 343.962v0c-21.972-.001-39.848-17.876-39.848-39.848v-66.176c0-21.972 17.876-39.847 39.848-39.847h103.83c.629.0 1.254.019 1.876.047v-65.922c0-16.969-13.756-30.725-30.725-30.725H30.726C13.756 101.49.0 115.246.0 132.215v277.621c0 16.969 13.756 30.726 30.726 30.726h380.943c16.969.0 30.725-13.756 30.725-30.726v-65.922c-.622.029-1.247.048-1.876.048H336.688z"/><path d="M440.518 219.925h-103.83c-9.948.0-18.013 8.065-18.013 18.013v66.176c0 9.948 8.065 18.013 18.013 18.013h103.83c9.948.0 18.013-8.064 18.013-18.013v-66.176C458.531 227.989 450.466 219.925 440.518 219.925zM372.466 297.024c-14.359.0-25.999-11.64-25.999-25.999s11.64-25.999 25.999-25.999c14.359.0 25.999 11.64 25.999 25.999C398.465 285.384 386.825 297.024 372.466 297.024z"/><path d="M358.169 45.209c-6.874-20.806-29.313-32.1-50.118-25.226L151.958 71.552h214.914L358.169 45.209z"/></g></g></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>


</a>

<a v-else class="float2"  :class="{'z_index_menu': show_menu}"  >

<b-dropdown   top  dropdown variant="light" size="sm" @hide="off">
        <template #button-content>

          <div class="float_div" @click="clickOpen"  >
            <svg width="25" class="my-float"  height="25"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 458.531 458.531" ><g id="XMLID_830_"><g><g><path d="M336.688 343.962v0c-21.972-.001-39.848-17.876-39.848-39.848v-66.176c0-21.972 17.876-39.847 39.848-39.847h103.83c.629.0 1.254.019 1.876.047v-65.922c0-16.969-13.756-30.725-30.725-30.725H30.726C13.756 101.49.0 115.246.0 132.215v277.621c0 16.969 13.756 30.726 30.726 30.726h380.943c16.969.0 30.725-13.756 30.725-30.726v-65.922c-.622.029-1.247.048-1.876.048H336.688z"/><path d="M440.518 219.925h-103.83c-9.948.0-18.013 8.065-18.013 18.013v66.176c0 9.948 8.065 18.013 18.013 18.013h103.83c9.948.0 18.013-8.064 18.013-18.013v-66.176C458.531 227.989 450.466 219.925 440.518 219.925zM372.466 297.024c-14.359.0-25.999-11.64-25.999-25.999s11.64-25.999 25.999-25.999c14.359.0 25.999 11.64 25.999 25.999C398.465 285.384 386.825 297.024 372.466 297.024z"/><path d="M358.169 45.209c-6.874-20.806-29.313-32.1-50.118-25.226L151.958 71.552h214.914L358.169 45.209z"/></g></g></g><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>

                    </div>
            
         
          </template>


<b-dropdown-item @click="onCripto('USDT')">
  <img src="/img/usdt.svg" style="margin-right: 10px;width:20px"/>Billetera TRC20 - USDT
 
</b-dropdown-item>



<b-dropdown-item @click="onCripto('BSC')">
 <img src="/img/bsc.svg" style="margin-right: 10px;width:20px"/>Billetera Binance - BNB

</b-dropdown-item>

</b-dropdown>


</a> 




</b-row>



</template>

<script>
import {
  BDropdown,BDropdownItem,VBTooltip, BLink,BButton,VBPopover, BRow, BCol, BCard,BForm
} from 'bootstrap-vue'
import PincodeInput from 'vue-pincode-input'
import Balances from './Balances.vue'
import Transacciones from './Transacciones.vue'
import CompraByMes from './CompraByMes.vue'
import CompraDisponible from './CompraDisponible.vue'
import LimiteDiarioConversion from './LimiteDiarioConversion.vue'
import Alquiler from './Alquiler.vue'

import carousel from 'vue-owl-carousel'
export default {
  components: {
    BButton,
    BDropdown,
    Balances,
    Alquiler,
    Transacciones,
    CompraDisponible,
    LimiteDiarioConversion,
    CompraByMes,
    BDropdownItem,
    carousel,
    BLink,
    BRow,
    BCol,
    BCard,
    PincodeInput,
    BForm,
  },
    directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  data() {

    const datos_json = JSON.parse(this.$userGlobal);
   


    let stagePadding=0;
            let margin=10;
                if(this.$isMobile){
                stagePadding=10;
                margin=5;

            }

 


    return {
      margin:margin,
      show_menu:false,
            stagePadding:stagePadding,
      dialogMobile:false,
      color: "#242526",
      indentColor:"#b0b3b8",
      overlay:"#000000e6",
      isMobile:this.$isMobile,
      isDisabledCampos:false,
      checkedUSDT: true,
      checkedBSC:false,
      radius:"10px",
      componentKeyWalletCripto: 0,
      componentKeyBalanceCripto:1,
      componentKeyAlquiler:2,
      componentKeyTransaccionesCripto:3,
      userId:datos_json.userId,
      tokenAuth:this.$tokenGlobal,
      datosWallet:[],
      currency:"USDT",
      address:datos_json.addressUSDT,
      idWallet:datos_json.idWalletUSDT,
      body: document.getElementsByTagName('body')[0],

    }
  },
  computed: {

  },
  watch: {

    dialogMobile(newValue) {


if(newValue){
  this.body.classList.add("overflow-hidden");
}else{
  this.body.classList.remove("overflow-hidden");
}



},

  },
  activated() {
    this.$eventBus.$emit('segundoMenu1')
       this.$eventBus.$emit('segundoMenu2')
},
  mounted() {


     
    document.title = 'Billeteras de criptomonedas / EQCoop'



    
    

    this.$eventBus.$on('reiniciarAlquiler', () => {
      this.componentKeyAlquiler += 1000
    });

    this.$eventBus.$on('reiniciarBalanceCripto', () => {
      this.componentKeyBalanceCripto += 2000
    });

        this.$eventBus.$on('reiniciarTransaccionesCripto', () => {
      this.componentKeyTransaccionesCripto += 3000
    });


  this.$eventBus.$on('reiniciarWallets', () => {
      this.componentKeyWalletCripto += 4000
    });






  },
  methods: {

    clickOpen(){

      this.show_menu=true;
      this.$refs.overlay.style.display="block";
   
    },

    off(){
      this.show_menu=false;
this.$refs.overlay.style.display="none";
},

    onCripto(value){


      if(value != this.currency){

        

               

        const userId_json = { userId: this.userId, currency:value }
           

              this.$swal({

                  html:
                                      '<img style="margin-left:10px;width: 30px " src="/img/loading.svg" alt="Loading" /> Abriendo billetera, espere',
                  showCloseButton: false,
                  showCancelButton: false,
                  focusConfirm: false,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  position: 'center',
                  showConfirmButton: false,
                })




                this.$httpsCryptos.post('/main/checkWallets/', { tokenAuth: this.tokenAuth, datos: userId_json }).then(response => {
              
                    
                      this.$swal.close()

              

                  if (response.data.code == 200) {
            
                  
                    this.datosWallet= response.data["datos"]

                    if(this.datosWallet != null){

                      this.currency =value;

                            switch(this.currency) {
                                    
                                        case "USDT":
                      
                                          this.checkedUSDT=true;
                     
                                            this.checkedBSC=false;
                                
                                        break;
                                  
                                        case "BSC":

                  
                                          this.checkedUSDT=false;
                          
                                            this.checkedBSC=true;
                                
                                        break;

                            }



                      

                          this.currency=this.datosWallet.currency;
                          this.address=this.datosWallet.address;
                


                          this.idWallet=this.datosWallet.id;
                          
                          this.$eventBus.$emit('reiniciarBalanceCripto')
                          this.$eventBus.$emit('reiniciarAlquiler')
                            this.$eventBus.$emit('reiniciarTransaccionesCripto')
                            this.$eventBus.$emit('reiniciarAddressWallet')
                            this.$eventBus.$emit('reiniciarMontoCompra', this.currency)
                            this.$eventBus.$emit('reiniciarCompraByMes', this.currency)

                            this.$eventBus.$emit('reiniciarLimiteDiarioConversion', this.currency)


                            

                            this.dialogMobile = false;



                    }else{

                      let currencyTemporal= value

                      if(currencyTemporal === "BSC"){
                        currencyTemporal="BNB";
                      }

           
                        let title= '<p style="font-size: 18px; !important">Usted no cuenta con una billetera de '+ currencyTemporal + ' creada <br> ¿Desea crearla ahora?<p>';


                        this.$swal({
                            title: title,
                            html:'<div <p style="font-weight:bold; text-align:center;"> Términos y condiciones de uso</p> <p style="font-size: 14px;text-align: justify;padding: 10px;"> Al aceptar los siguientes Términos y Condiciones usted confirma y ratifica que éste acuerdo de usuario es un contrato entre usted y EQCoop. Usted, adicionalmente, acepta, entiende y se compromete al cumplimiento estricto de sus anexos incluidos en éste documento. Los Términos y Condiciones, además, incluyen un acuerdo para resolver disputas por arbitraje de manera individual si fuera el caso.</p><p> <span style="margin-right:10px">¿Desea seguir leyendo? </span> <a style="color: #00838c;" href="https://firebasestorage.googleapis.com/v0/b/eqpayapp.appspot.com/o/PDF%20de%20terminos%20y%20condiciones%2FTerminos%20y%20condiciones%20EQCoop.pdf?alt=media&token=4f2d9aad-b7db-4af9-98c0-cc32bc15665f" target="_blank">Descargar términos y condiciones</a></p></div>',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Aceptar términos',
                            cancelButtonText: 'Cancelar',
                            customClass: {
                              confirmButton: 'btn btn-primary',
                              cancelButton: 'btn btn-outline-secondary ml-1',
                            },
                            buttonsStyling: false,
                            showLoaderOnConfirm: true,
                            preConfirm: () => {


                              return this.$httpsCryptos.post('/main/createWallet/', { tokenAuth: this.tokenAuth, datos: userId_json }).then(response => response.data).catch(error => {
                                this.$swal.showValidationMessage(
                                  error,
                                )
                              })
                            },
                            allowOutsideClick: () => !this.$swal.isLoading(),
                          }).then(result => {

                            this.$swal.close()

                            if(result.isConfirmed){

                              if (result.value.code == 200) {

                                switch(this.currency) {
                              
                                        case "USDT":
                                  
                                          this.checkedUSDT=true;
                                   
                                            this.checkedBSC=false;
                                
                                        break;
                                
                                        case "BSC":

                      
                                          this.checkedUSDT=false;
                                  
                                            this.checkedBSC=true;
                                
                                        break;

                            }

                                
                                      
                                        this.datosWallet= result.value.datos

                                      this.currency=this.datosWallet.currency;
                                      this.address=this.datosWallet.address;
                                    

                                      this.idWallet=this.datosWallet.id;

                                      this.$eventBus.$emit('reiniciarBalanceCripto')
                                      this.$eventBus.$emit('reiniciarAlquiler')
                                        this.$eventBus.$emit('reiniciarTransaccionesCripto')
                                        this.$eventBus.$emit('reiniciarAddressWallet')
                                        this.$eventBus.$emit('reiniciarMontoCompra', this.currency)
                                        this.$eventBus.$emit('reiniciarCompraByMes', this.currency)
                                        this.dialogMobile = false;
                                        this.$eventBus.$emit('reiniciarLimiteDiarioConversion', this.currency)

                                              


                        
                                      } else {
                                        this.$toast.error(result.value.message, {
                                          position: 'top-right',
                                          timeout: 3010,
                                          closeOnClick: true,
                                          pauseOnFocusLoss: false,
                                          pauseOnHover: true,
                                          draggable: true,
                                          draggablePercent: 0.4,
                                          showCloseButtonOnHover: false,
                                          hideProgressBar: true,
                                          closeButton: 'button',
                                          icon: true,
                                          rtl: false,
                                        })

                                        if (result.data.code == 401) {
                                          
                                          localStorage.removeItem('userData')

        

                                          
                                          

                              

                                          
                                          

                                          // Redirect to login page
                                          this.$router.push({ name: 'auth-login' })
                                        }
                                      }

                            }
                            



                      
                          })



                    }


                  
                  } else {

                          this.$toast.error(response.data.message, {
                          position: 'top-right',
                          timeout: 2000,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })


                    if (response.data.code == 401) {
                      
                      localStorage.removeItem('userData')

        

                      
                      

                      

                      
                      

                      // Redirect to login page
                      this.$router.push({ name: 'auth-login' })
                    } 
                  }
                }).catch(error => {
                    
                })


      }else{

        this.$toast.success("Ya tienes abierto esta billetera", {
                                          position: 'top-right',
                                          timeout: 3010,
                                          closeOnClick: true,
                                          pauseOnFocusLoss: false,
                                          pauseOnHover: true,
                                          draggable: true,
                                          draggablePercent: 0.4,
                                          showCloseButtonOnHover: false,
                                          hideProgressBar: true,
                                          closeButton: 'button',
                                          icon: true,
                                          rtl: false,
                                        })

      }

    },
    onChange(event) {
            
           
           

              if(event.target.value === this.currency){

                this.dialogMobile = false;

           
              }else{

               

                const userId_json = { userId: this.userId, currency:event.target.value }
             

              this.$swal({

                  html:
                                      '<img style="margin-left:10px;width: 30px " src="/img/loading.svg" alt="Loading" /> Abriendo billetera, espere',
                  showCloseButton: false,
                  showCancelButton: false,
                  focusConfirm: false,
                  allowEscapeKey: false,
                  allowOutsideClick: false,
                  position: 'center',
                  showConfirmButton: false,
                })




                this.$httpsCryptos.post('/main/checkWallets/', { tokenAuth: this.tokenAuth, datos: userId_json }).then(response => {
              
                    
                      this.$swal.close()


                  if (response.data.code == 200) {
            
                  
                    this.datosWallet= response.data["datos"]

                    if(this.datosWallet != null){

                      this.currency = event.target.value;

                            switch(this.currency) {
                                    
                                     
                                        case "USDT":
                                      
                                          this.checkedUSDT=true;
                              
                                            this.checkedBSC=false;
                                
                                        break;
                                       
                                        case "BSC":

                                       
                                          this.checkedUSDT=false;
                               
                                            this.checkedBSC=true;
                                
                                        break;

                            }



                      

                          this.currency=this.datosWallet.currency;
                          this.address=this.datosWallet.address;
                


                          this.idWallet=this.datosWallet.id;

                          this.$eventBus.$emit('reiniciarBalanceCripto')
                          this.$eventBus.$emit('reiniciarAlquiler')
                            this.$eventBus.$emit('reiniciarTransaccionesCripto')
                            this.$eventBus.$emit('reiniciarAddressWallet')
                            this.$eventBus.$emit('reiniciarMontoCompra', this.currency)
                            this.$eventBus.$emit('reiniciarCompraByMes', this.currency)
                            this.dialogMobile = false;

                            this.$eventBus.$emit('reiniciarLimiteDiarioConversion', this.currency)

                    }else{

                      let currencyTemporal= event.target.value;

                        if(currencyTemporal === "BSC"){
                          currencyTemporal="BNB";
                        }

                        let title= '<p style="font-size: 18px; !important">Usted no cuenta con una billetera de '+ currencyTemporal + ' creada <br> ¿Desea crearla ahora?<p>';


                        this.$swal({
                            title: title,
                            html:'<div <p style="font-weight:bold; text-align:center;"> Términos y condiciones de uso</p> <p style="font-size: 14px;text-align: justify;padding: 10px;"> Al aceptar los siguientes Términos y Condiciones usted confirma y ratifica que éste acuerdo de usuario es un contrato entre usted y EQCoop. Usted, adicionalmente, acepta, entiende y se compromete al cumplimiento estricto de sus anexos incluidos en éste documento. Los Términos y Condiciones, además, incluyen un acuerdo para resolver disputas por arbitraje de manera individual si fuera el caso.</p><p> <span style="margin-right:10px">¿Desea seguir leyendo? </span> <a style="color: #00838c;" href="https://firebasestorage.googleapis.com/v0/b/eqpayapp.appspot.com/o/PDF%20de%20terminos%20y%20condiciones%2FTerminos%20y%20condiciones%20EQCoop.pdf?alt=media&token=4f2d9aad-b7db-4af9-98c0-cc32bc15665f" target="_blank">Descargar términos y condiciones</a></p></div>',
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonText: 'Aceptar términos',
                            cancelButtonText: 'Cancelar',
                            customClass: {
                              confirmButton: 'btn btn-primary',
                              cancelButton: 'btn btn-outline-secondary ml-1',
                            },
                            buttonsStyling: false,
                            showLoaderOnConfirm: true,
                            preConfirm: () => {


                              return this.$httpsCryptos.post('/main/createWallet/', { tokenAuth: this.tokenAuth, datos: userId_json }).then(response => response.data).catch(error => {
                                this.$swal.showValidationMessage(
                                  error,
                                )
                              })
                            },
                            allowOutsideClick: () => !this.$swal.isLoading(),
                          }).then(result => {

                            this.$swal.close()

                            if(result.isConfirmed){

                              if (result.value.code == 200) {

                                switch(this.currency) {
                                    
                                        case "USDT":
                                      
                                          this.checkedUSDT=true;
                         
                                            this.checkedBSC=false;
                                
                                        break;
                                     
                                        case "BSC":

                                    
                                          this.checkedUSDT=false;
                       
                                            this.checkedBSC=true;
                                
                                        break;

                            }

                                
                                      
                                        this.datosWallet= result.value.datos

                                      this.currency=this.datosWallet.currency;
                                      this.address=this.datosWallet.address;
                                    

                                      this.idWallet=this.datosWallet.id;

                                      this.$eventBus.$emit('reiniciarBalanceCripto')
                                       this.$eventBus.$emit('reiniciarAlquiler')
                                        this.$eventBus.$emit('reiniciarTransaccionesCripto')
                                        this.$eventBus.$emit('reiniciarAddressWallet')
                                        this.$eventBus.$emit('reiniciarMontoCompra', this.currency)
                                        this.dialogMobile = false;
                                        this.$eventBus.$emit('reiniciarCompraByMes', this.currency)
                                        this.$eventBus.$emit('reiniciarLimiteDiarioConversion', this.currency)
                                              


                        
                                      } else {
                                        this.$toast.error(result.value.message, {
                                          position: 'top-right',
                                          timeout: 3010,
                                          closeOnClick: true,
                                          pauseOnFocusLoss: false,
                                          pauseOnHover: true,
                                          draggable: true,
                                          draggablePercent: 0.4,
                                          showCloseButtonOnHover: false,
                                          hideProgressBar: true,
                                          closeButton: 'button',
                                          icon: true,
                                          rtl: false,
                                        })

                                        if (result.data.code == 401) {
                                          
                                          localStorage.removeItem('userData')

        

                                          
                                          

                              

                                          
                                          

                                          // Redirect to login page
                                          this.$router.push({ name: 'auth-login' })
                                        }
                                      }

                            }
                            



                      
                          })



                    }


                  
                  } else {

                          this.$toast.error(response.data.message, {
                          position: 'top-right',
                          timeout: 2000,
                          closeOnClick: true,
                          pauseOnFocusLoss: false,
                          pauseOnHover: true,
                          draggable: true,
                          draggablePercent: 0.4,
                          showCloseButtonOnHover: false,
                          hideProgressBar: true,
                          closeButton: 'button',
                          icon: true,
                          rtl: false,
                        })


                    if (response.data.code == 401) {
                      
                      localStorage.removeItem('userData')

        

                      
                      

                      

                      
                      

                      // Redirect to login page
                      this.$router.push({ name: 'auth-login' })
                    } 
                  }
                }).catch(error => {
                    
                })

              }


   
          },  
    onCopy() {
      this.$toast.success('Address copiado', {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    },
    onError() {
      this.$toast.error('Falló en copiar el address', {
        position: 'top-right',
        timeout: 1500,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.4,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: 'button',
        icon: true,
        rtl: false,
      })
    },  


  },
}
</script>

<style lang="scss">




</style>
